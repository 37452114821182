import { useState } from 'react';

const useReferences = () => {
  const [mobileHeaderRef, setMobileHeaderRef] = useState({ current: null });
  const [updateBarRef, setUpdateBarRef] = useState({ current: null });
  const [noInternetBarRef, setNoInternetBarRef] = useState({ current: null });
  const [systemOfflineBannerRef, setSystemOfflineBannerRef] = useState({ current: null });

  return {
    mobileHeader: {
      ref: mobileHeaderRef,
      setRef: setMobileHeaderRef,
    },
    updateBar: {
      ref: updateBarRef,
      setRef: setUpdateBarRef,
    },
    noInternetBar: {
      ref: noInternetBarRef,
      setRef: setNoInternetBarRef,
    },
    systemOfflineBanner: {
      ref: systemOfflineBannerRef,
      setRef: setSystemOfflineBannerRef,
    },
  };
};

export default useReferences;
