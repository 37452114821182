import React from 'react';
import { observer } from 'mobx-react';

import { FridgeIcon, KegeratorIcon, GlycolIcon, GatewayIcon } from 'assets/icons';

const GatewayIconComponent = ({ type_id, styles = {} }) => {
  const defaultStyles = {
    margin: '0 8px',
    width: '24px',
    height: '24px',
    ...styles,
  };

  if ([7, 8].includes(type_id)) {
    return <KegeratorIcon style={defaultStyles} />;
  } else if ([2, 3, 4, 5, 6].includes(type_id)) {
    return <GlycolIcon style={defaultStyles} />;
  } else if (type_id === 0) {
    return <GatewayIcon style={defaultStyles} />;
  } else {
    return <FridgeIcon style={defaultStyles} />;
  }
};

export default observer(GatewayIconComponent);
