import { CheckCircle, Close, HelpOutline, InfoOutlined, Refresh } from '@mui/icons-material';
import { Warning } from '@mui/icons-material/';
import { Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import TroubleshootModal from 'components/shared/SystemOffline/SystemOfflineBanner/desktop/TroubleshootModal';
import { useState } from 'react';
import { stringUtilities } from 'utils';

const SystemOfflineBanner = ({
  fullSystemOffline,
  systemRestored,
  exitBanner,
  refreshTimeLeft,
  handleRefresh,
  isRefreshing,
  onlineCountdown,
  isBackOnline,
  handleDetailsClick,
}) => {
  const [troubleshootingModalOpen, setTroubleshootingModalOpen] = useState(false);

  const handleTroubleshootingClick = () => {
    setTroubleshootingModalOpen(!troubleshootingModalOpen);
  };

  return (
    <>
      <StyledRootDiv className={clsx({ restored: systemRestored === true })}>
        <div className={'menuOptions'}>
          <span className={'menuOption'}>
            {systemRestored === true ? (
              <CheckCircle />
            ) : (
              <Warning sx={{ width: '24px' }} htmlColor={'#000'} />
            )}
            <Typography variant={'h6'} className={'menuOptionText systemOfflineText'}>
              {systemRestored
                ? 'System Connectivity Restored'
                : fullSystemOffline
                ? 'System Offline'
                : 'Partial System Offline'}
            </Typography>
          </span>
          |
          {systemRestored ? (
            <span className={'menuOption'}>
              <span className={'menuText'} onClick={exitBanner}>
                <Close />
                Close
              </span>
              <span className={clsx('timer online', { active: isBackOnline })}>
                {stringUtilities.formatTimer(onlineCountdown)}
              </span>
            </span>
          ) : (
            <>
              <span className={'menuOption'} onClick={handleTroubleshootingClick}>
                <HelpOutline htmlColor={'#000'} />
                <Typography variant={'h6'} className={'menuOptionText'}>
                  Troubleshooting
                </Typography>
              </span>
              |
              <span className={'menuOption'} onClick={handleDetailsClick}>
                <InfoOutlined htmlColor={'#000'} />
                <Typography variant={'h6'} className={'menuOptionText'}>
                  Details
                </Typography>
              </span>
              |
              <Tooltip
                title={
                  isRefreshing
                    ? 'System requires up to 5 minutes to restart. Please wait for the process to complete.'
                    : ''
                }
              >
                <span className={'menuOption'} onClick={handleRefresh}>
                  <Refresh htmlColor={isRefreshing ? 'rgba(0,0,0,0.5)' : '#000'} />
                  <Typography
                    variant={'h6'}
                    className={clsx('menuOptionText', { refreshing: isRefreshing })}
                  >
                    Refresh
                  </Typography>
                  <span className={clsx('timer refresh', { active: isRefreshing })}>
                    {stringUtilities.formatTimer(refreshTimeLeft)}
                  </span>
                </span>
              </Tooltip>
            </>
          )}
        </div>
      </StyledRootDiv>
      <TroubleshootModal open={troubleshootingModalOpen} onClose={handleTroubleshootingClick} />
    </>
  );
};

const StyledRootDiv = styled('div')(({ theme: { palette } }) => ({
  height: '40px',
  backgroundColor: palette.status.red,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&.restored': {
    backgroundColor: palette.status.green,
  },
  '.menuOptions': {
    display: 'flex',
    color: palette.black,
  },
  '.menuOption': {
    cursor: 'pointer',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridColumnGap: '4px',
    alignItems: 'center',
    margin: '0 16px',
  },
  '.menuText': {
    display: 'flex',
    alignItems: 'center',
  },
  '.menuOptionText': {
    color: palette.black,
    '&.refreshing': {
      color: 'rgba(0,0,0,0.5)',
    },
    '&.systemOfflineText': {
      cursor: 'default',
    },
  },
  '.timer': {
    backgroundColor: palette.black,
    borderRadius: '10px',
    fontSize: '14px',
    lineHeight: '16px',
    padding: '0 8px',
    opacity: 0,
    transition: 'opacity 0.5s',
    width: '57px',
    '&.online': {
      color: palette.status.green,
    },
    '&.refresh': {
      color: palette.status.red,
    },
    '&.active': {
      opacity: 1,
    },
  },
}));

export default SystemOfflineBanner;
