import { types, flow } from 'mobx-state-tree';
import { sortBy, groupBy } from 'lodash';
import { format, subDays, differenceInDays } from 'date-fns';

import api from 'services/API';
import { getRootStore } from 'models/root';
import { normalize } from 'utils/diacriticNormalizer';

import {
  Alarm,
  BRU,
  DeviceConnectivityHistory,
  Gateway,
  LineSensor,
  Sensor,
  TemperatureSensor,
  EstablishmentSensors,
  SensorMetadata,
} from 'models/types';

export const topologyManagementInitialState = {
  isLoaded: false,
  gateways: [],
  state: 'done',
  updated: null,
  alarms: [],
  brus: [],
  sensors: [],
  temperatureSensors: [],
  lineSensors: [],
  isMultiSelect: false,
  selectedLineIds: [],
  establishments_sensors: null,
  searchString: '',
  filters: {
    orderBy: 'asc',
    excludedCoolersIds: [],
    excludedGatewaysIds: [],
    excludedBRUsIds: [],
    selectedLineIDs: [],
  },
  selectedCoolerID: null,
  selectedGatewayID: null,
  period: {
    from: format(subDays(new Date(), 1), 'yyyy-MM-dd HH:mm:ss'),
    to: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
  },
  dataset: [],
  connectivityHistory: {
    gateways: [],
    brus: [],
    sensors: [],
  },
  selectedGatewayId: null,
  selectedSensorMetadata: null,
};

export const topologyManagementModel = types
  .model({
    isLoaded: types.boolean,
    state: types.enumeration('state', ['done', 'pending', 'error']),
    updated: types.maybeNull(types.Date),
    alarms: types.array(Alarm),
    gateways: types.array(Gateway),
    brus: types.array(BRU),
    sensors: types.array(Sensor),
    temperatureSensors: types.array(TemperatureSensor),
    lineSensors: types.array(LineSensor),
    isMultiSelect: types.boolean,
    selectedLineIds: types.array(types.number),
    establishments_sensors: types.maybeNull(EstablishmentSensors),
    searchString: types.maybeNull(types.string),
    filters: types.maybeNull(
      types.model({
        excludedCoolersIds: types.array(types.number),
        excludedGatewaysIds: types.array(types.number),
        excludedBRUsIds: types.array(types.number),
        orderBy: types.enumeration('orderBy', ['asc', 'desc']),
        selectedLineIDs: types.array(types.number),
      }),
    ),
    selectedCoolerID: types.maybeNull(types.number),
    selectedGatewayID: types.maybeNull(types.number),
    period: types.model({
      from: types.string,
      to: types.string,
    }),
    connectivityHistory: DeviceConnectivityHistory,
    selectedGatewayId: types.maybeNull(types.integer),
    selectedSensorMetadata: types.maybeNull(SensorMetadata),
  })
  .views(self => ({
    get sortedGateways() {
      const grouped = groupBy(self.gateways, 'cooler_id');
      const result = [];
      Object.values(grouped).forEach(items => {
        if (items.length > 1) {
          const filtered = items.filter(item => item.type_id && item.type_id !== 0);
          result.push(...filtered);
        } else {
          result.push(...items);
        }
      });

      return sortBy(result, '_coolers_name');
    },

    get subscribedAlarmsIds() {
      const root = getRootStore();
      const roleUserId = root.userStore.profile.currentEstablishmentRole._roleuser_id;
      return self.alarms
        .filter(({ role_users_ids }) => role_users_ids?.includes(roleUserId))
        .map(({ id }) => id);
    },

    get mappedEquipment() {
      const root = getRootStore();

      const mergedGateways = self.gateways.map(gateway => {
        const gateway_ES =
          self.establishments_sensors.gateways?.find(g => g.gateway_id === gateway.id) || {};
        const mergedBrus = self.brus
          .filter(bru => bru._gateways_id === gateway.id)
          .map(bru => {
            const bru_ES = gateway_ES?.brus?.find(b => b.bru_id === bru.id) || {};
            const mergedSensors = self.sensors
              .filter(s => s.bru_id === bru.id)
              .map(sensor => {
                const sensor_ES = bru_ES?.sensors?.find(s => s.sensor_id === sensor.id) || {};
                const lineSensor = self.lineSensors?.find(ls => ls.sensor_id === sensor.id);
                const line = lineSensor
                  ? root.linesStore.lines?.find(line => line.id === lineSensor.line_id)
                  : null;

                return {
                  ...sensor_ES,
                  ...sensor,
                  line,
                };
              });

            return {
              ...bru,
              ...bru_ES,
              latest_pour: mergedSensors
                .map(s => s.latest_pour_poured_at)
                .filter(Boolean)
                .sort()
                .reverse()[0],
              latest_heartbeat_received_at: mergedSensors
                .map(s => s.latest_heartbeat_received_at)
                .filter(Boolean)
                .sort()
                .reverse()[0],
              sensors: mergedSensors.sort((a, b) => a.bru_sensor_address - b.bru_sensor_address),
            };
          });

        const mergedTempSensors = self.temperatureSensors
          .filter(ts => ts._gateways_id === gateway.id)
          .map(tempSensor => {
            const tempSensor_ES = gateway_ES?.temp_sensors?.find(s => s.id === tempSensor.id);
            return {
              ...tempSensor_ES,
              ...tempSensor,
            };
          });

        const groupedBRUs = groupBy(mergedBrus, 'gateway_bru_address');

        const replacedBRUs = Object.values(groupedBRUs).map(bruArr => {
          const sortedByEnumeratedAt = bruArr.sort(
            (a, b) => Date.parse(b.enumerated_at) - Date.parse(a.enumerated_at),
          );

          const mostEnumeratedSensors = bruArr
            .map(bru => {
              if (bru.sensors.length && bru.sensors.some(s => s.line)) {
                const enumeratedSensors = bru.sensors
                  .filter(s => s.line)
                  .map(s => s.enumerated_at)
                  .sort((a, b) => Date.parse(b) - Date.parse(a));

                const mostRecentSensorsEnumeratedAt = enumeratedSensors[0];
                return {
                  ...bru,
                  sensorsEnumeratedAt: mostRecentSensorsEnumeratedAt,
                };
              } else return null;
            })
            .filter(Boolean)
            .sort((a, b) => Date.parse(b.sensorsEnumeratedAt) - Date.parse(a.sensorsEnumeratedAt));

          if (
            mostEnumeratedSensors[0] &&
            mostEnumeratedSensors[0].id !== sortedByEnumeratedAt[0].id
          ) {
            return { ...mostEnumeratedSensors[0], replacementDetected: true };
          } else {
            return { ...sortedByEnumeratedAt[0], replacementDetected: false };
          }
        });

        return {
          ...gateway_ES,
          ...gateway,
          brus: replacedBRUs.sort((a, b) => a.gateway_bru_address - b.gateway_bru_address),
          temp_sensors: mergedTempSensors,
          bru_sensors_count: replacedBRUs
            ?.map(({ sensors }) => sensors.length)
            .reduce((sum, curr) => sum + curr, 0),
        };
      });

      return root.coolersStore.all.map(cooler => {
        const gateways = groupBy(mergedGateways, 'cooler_id')[cooler.id] || [];
        return {
          ...cooler,
          gateways: gateways.map(g => {
            const gw_types = [];

            if (g.brus?.length) gw_types.push('bru');
            if (g.temp_sensors?.length) gw_types.push('temperature');
            if (gw_types.length === 0) gw_types.push('empty');

            return {
              ...g,
              gw_types,
            };
          }),
        };
      });
    },

    get filteredEquipment() {
      let result = self.mappedEquipment || [];

      if (self.searchString) {
        const searchArray = self.searchString.toLowerCase().split(' ');

        result = result
          .map(cooler => ({
            ...cooler,
            gateways: cooler.gateways
              .map(gateway => ({
                ...gateway,
                brus: gateway.brus
                  .map(bru => ({
                    ...bru,
                    sensors: bru.sensors.filter(sensor => {
                      if (!sensor.line?.item?._beverages_name) return false;

                      const every = searchArray.every(
                        element =>
                          normalize(sensor.line?.item?.beverage?._name)
                            .toLowerCase()
                            .includes(element) ||
                          normalize(sensor.line?.item?.beverage?._producers_name)
                            .toLowerCase()
                            .includes(element),
                      );

                      return every;
                    }),
                  }))
                  .filter(({ sensors }) => !!sensors.length),
              }))
              .filter(({ brus }) => !!brus.length),
          }))
          .filter(({ gateways }) => !!gateways.length);
      }

      result = result.filter(({ id }) => !self.filters.excludedCoolersIds.includes(id));

      result = result
        .map(cooler => {
          const filteredGateways = cooler.gateways
            .map(gateway => ({
              ...gateway,
              brus: gateway?.brus?.filter(({ id }) => !self.filters.excludedBRUsIds.includes(id)),
            }))
            .filter(({ id }) => !self.filters.excludedGatewaysIds.includes(id));

          return {
            ...cooler,
            gateways: filteredGateways.map(g => {
              const gw_types = [];

              if (g.brus?.length) gw_types.push('bru');
              if (g.temp_sensors?.length) gw_types.push('temperature');
              if (gw_types.length === 0) gw_types.push('empty');

              return {
                ...g,
                gw_types,
              };
            }),
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      return self.filters.orderBy === 'asc' ? result : result.reverse();
    },

    get unmappedEquipment() {
      return self.gateways.filter(gw => !gw.cooler_id);
    },

    get mappedLines() {
      if (!self.gateways.length) return [];

      const root = getRootStore();
      const allLines = root.linesStore.all.map(e => e);
      const mappedLinesIds = self.lineSensors.map(ls => ls.line_id);
      return allLines.filter(line => mappedLinesIds.includes(line.id));
    },

    get isDefaultFilters() {
      return (
        self.filters.orderBy === 'asc' &&
        self.filters.excludedCoolersIds.length === 0 &&
        self.filters.excludedGatewaysIds.length === 0 &&
        self.filters.excludedBRUsIds.length === 0 &&
        self.filters.selectedLineIDs.length === 0
      );
    },

    get selectedSensor() {
      if (!self.selectedLineIds.length) return null;
      const lineSensor = self.lineSensors.find(({ line_id }) =>
        self.selectedLineIds.includes(line_id),
      );

      return self.mappedEquipment
        .flatMap(({ gateways }) =>
          gateways.flatMap(({ brus }) => brus.flatMap(({ sensors }) => sensors)),
        )
        .find(({ id }) => lineSensor.sensor_id === id);
    },

    get hasMappedGateways() {
      return Boolean(self.gateways.filter(gw => !!gw.cooler_id)?.length);
    },

    get equipmentBySelectedCooler() {
      return self.filteredEquipment.filter(({ id }) => id === self.selectedCoolerID);
    },

    get bins() {
      // Used for API and calculation number of X axis chart labels
      return 96;
    },
  }))
  .actions(self => ({
    fetchDeviceConnectivityHistory: flow(function* () {
      try {
        self.isLoaded = false;

        const response = yield api.getDeviceConnectivityHistory({
          gateway_id: self.selectedGatewayId,
          from_ts: new Date(self.period.from).toISOString(),
          to_ts: new Date(self.period.to).toISOString(),
          bins: self.bins,
        });
        self.setConnectivityHistory(response.data?.result);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      } finally {
        self.isLoaded = true;
      }
    }),

    fetchSensorDiagnosticsInfo: flow(function* (params) {
      try {
        self.setSensorMetaInfo(null);
        const response = yield api.getSensorDiagnosticInfo(params);
        self.setSensorMetaInfo(response.data);
      } catch (error) {
        if (
          error?.response?.data?.error?.message ===
          'Request timeout. This might happen if wrong gateway identifier was provided or if device is offline'
        ) {
          self.setSensorMetaInfo({
            latest_pour: null,
            latest_calibration: null,
            pga_gain: null,
            sensor_vfr: null,
            volume_scale_factor: null,
            calibrated_tof: null,
            signal_strength: null,
            sensor_temp_c: null,
          });
        } else {
          console.log(error);

          // return Promise.reject(error);
        }
      }
    }),

    patchGateway: flow(function* (id, body) {
      try {
        const response = yield api.patchGateways(body, id);
        const updatedGateway = response.data?.row;

        self.gateways.replace([
          ...self.gateways.filter(e => e.id !== updatedGateway.id),
          updatedGateway,
        ]);
        return updatedGateway;
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    patchAlert: flow(function* (body, params) {
      try {
        const response = yield api.patchAlerts(body, params);

        const updatedAlarms = response.data.result;
        const updatedIds = updatedAlarms.map(({ id }) => id);

        self.alarms = [
          ...self.alarms.filter(({ id }) => !updatedIds.includes(id)),
          ...updatedAlarms,
        ];
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    replaceBRU: flow(function* (body) {
      try {
        const response = yield api.replaceBRU(body);

        const { connected, disconnected } = response?.data?.result;

        const disconnectedLineSensorsIDs = disconnected._line_sensors.map(({ id }) => id);
        self.lineSensors.replace([
          ...self.lineSensors.filter(({ id }) => !disconnectedLineSensorsIDs.includes(id)),
          ...disconnected._line_sensors,
          ...connected._line_sensors,
        ]);

        self.brus.replace([
          ...self.brus.filter(({ id }) => disconnected._bru.id !== id),
          disconnected._bru,
          connected._bru,
        ]);

        return response?.data?.result;
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    getLastPour: function () {
      return new Date(Date.now() - 1000 * 60 * 60 * 24 * 3).toISOString();
    },

    disconnectLinesSensors: flow(function* (line_ids) {
      try {
        line_ids.map(line_id =>
          api.disconnectCurrentItem({
            line_id,
            item_status_code: 2,
          }),
        );
        const response = yield api.disconnectCurrentSensors(
          line_ids.map(line_id => ({
            line_id,
          })),
        );

        if (response?.data?.result) {
          const updated = response?.data?.result?.disconnected._line_sensor;
          const updatedIds = updated.map(({ id }) => id);
          self.lineSensors.replace(self.lineSensors.filter(e => !updatedIds.includes(e.id)));

          return self.lineSensors;
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    connectNewLineToSensor: flow(function* (sensor_id, body) {
      try {
        const root = getRootStore();
        const newLineResponse = yield api.createLine(body);

        const newTapResponse = yield api.createTap({
          establishment_id: body.establishment_id,
          identifier: `Tap-${body.sort_value}`,
          status_code: 0,
          archived: false,
        });

        const connectLineTapResponse = yield api.connectNewLineTap([
          {
            line_id: newLineResponse?.data?.id,
            tap_id: newTapResponse?.data?.id,
          },
        ]);

        root.lineTapsStore.setLineTaps([
          ...root.lineTapsStore.lineTaps,
          ...connectLineTapResponse.data?.result?.connected?._line_taps,
        ]);

        if (newLineResponse?.data?.row) {
          root.linesStore.setLines([
            ...root.linesStore.all.map(e => e),
            newLineResponse?.data?.row,
          ]);
        }

        const connectLineSensorResponse = yield api.connectNewLineSensor({
          line_id: newLineResponse?.data?.id,
          sensor_id: sensor_id,
        });

        if (connectLineSensorResponse.data?.result?.connected?._line_sensors) {
          self.lineSensors.push(
            connectLineSensorResponse.data?.result?.connected?._line_sensors[0],
          );
        }

        return newLineResponse?.data?.row;
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    updateTapNumber: async data => {
      try {
        const root = getRootStore();

        await root.tapsStore.patchTap(data.tap_id, data.tap_body);
        await root.linesStore.patchLine(data.line_id, data.line_body);
      } catch (err) {
        return Promise.reject(err);
      }
    },

    getCoolerIdByGatewayId(id) {
      return self.gateways.find(g => g.id === id)?.cooler_id;
    },

    getUsedIdentifiersBySensor(_sensor) {
      const coolerId = self.gateways.find(g => g.id === _sensor?._gateways_id)?.cooler_id;
      const cooler = self.mappedEquipment.find(cooler => cooler.id === coolerId);

      if (!_sensor?._gateways_id || !coolerId) return [];

      return cooler?.gateways
        ?.flatMap(g => g?.brus?.flatMap(({ sensors }) => sensors))
        .filter(sensor => Boolean(sensor?.line) && sensor.id !== _sensor.id)
        .map(({ id, line: { identifiers } }) => ({
          id,
          lineId: identifiers.line.numerical,
          tapId: identifiers?.taps[0]?.numerical,
        }));
    },

    getFilteredItemsByKey(key) {
      switch (key) {
        case 'cooler':
          return self.filteredEquipment;
        case 'gateway':
          return self.filteredEquipment.flatMap(({ gateways }) => gateways);
        case 'bru':
          return self.filteredEquipment.flatMap(({ gateways }) =>
            gateways.flatMap(({ brus }) => brus),
          );
        case 'sensor':
          return self.filteredEquipment.flatMap(({ gateways }) =>
            gateways.flatMap(({ brus }) => brus).flatMap(({ sensors }) => sensors),
          );
        default:
          return [];
      }
    },

    setAlarms(rows) {
      self.alarms.replace(rows);
    },

    setBRUs(rows) {
      self.brus.replace(rows.sort((a, b) => b.id - a.id));
    },

    setGateways(rows) {
      self.gateways.replace(rows);
    },

    setSensors(rows) {
      self.sensors.replace(rows);
    },

    setTemperatureSensors(rows) {
      self.temperatureSensors.replace(rows);
    },

    setLineSensors(rows) {
      // no connected_to date or it in the future
      const filtered = rows.filter(
        ({ connected_to }) => !connected_to || Date.parse(connected_to) > Date.now(),
      );

      self.lineSensors.replace(filtered);
    },

    setMultiSelect(flag) {
      self.isMultiSelect = flag;
    },

    setSelectedLines(ids) {
      self.selectedLineIds = ids;
    },

    setEstablishmentSensors(rows) {
      self.establishments_sensors = rows;
    },

    setSearchString(value) {
      self.searchString = value;
    },

    setFilters(filters) {
      self.filters = {
        ...self.filters,
        ...filters,
      };
    },
    resetFilters() {
      self.filters = topologyManagementInitialState.filters;
    },

    setIsLoaded(value) {
      self.isLoaded = value;
    },

    setSelectedGatewayID(id) {
      const idsToExclude = !id
        ? []
        : self.gateways.filter(gateway => gateway.id !== id).map(({ id }) => id);

      self.selectedGatewayID = id;
      self.filters = {
        ...self.filters,
        excludedGatewaysIds: idsToExclude,
      };
    },

    setSelectedCoolerID(id) {
      self.selectedCoolerID = id;
      self.selectedGatewayID = null;
      self.filters = {
        ...self.filters,
        excludedGatewaysIds: [],
      };
    },

    updateGateway(_gateway) {
      const index = self.gateways.findIndex(g => g.id === _gateway.id);

      if (index >= 0) {
        Object.assign(self.gateways[index], {
          ...self.gateways[index],
          latest_sample_received_at: _gateway.latest_sample_received_at,
        });
      }
    },

    handleHeartbeat(result) {
      if (!result || !Array.isArray(result) || !result.length) return;

      result.forEach(heartbeat => {
        const { sensor_id, sensor_temp_c, received_at } = heartbeat;

        const defaultSensor = self.sensors.find(s => s.id === sensor_id);
        const temperatureSensor = self.temperatureSensors.find(s => s.id === sensor_id);

        if (defaultSensor) {
          self.sensors.replace([
            ...self.sensors.filter(e => e.id !== defaultSensor.id),
            {
              ...defaultSensor,
              latest_heartbeat_received_at: received_at,
              latest_heartbeat_age_seconds: (Date.now() - Date.parse(received_at)) / 1000,
              latest_heartbeat_temperature_c: sensor_temp_c,
            },
          ]);
          const bru = self.brus.find(b => b.id === defaultSensor.bru_id);
          if (bru) {
            const gateway = self.gateways.find(g => g.id === bru._gateways_id);
            if (gateway) {
              self.brus.replace([
                ...self.brus.filter(e => e.id !== bru.id),
                {
                  ...bru,
                  latest_heartbeat_received_at: received_at,
                },
              ]);
              self.updateGateway({
                ...gateway,
                latest_sample_received_at: received_at,
              });
            } else {
              console.warn(`Gateway not found for bru with _gateways_id: ${bru._gateways_id}`);
            }
          } else {
            console.warn(`BRU not found for sensor bru_id: ${defaultSensor.bru_id}`);
          }
        }

        if (temperatureSensor) {
          const gateway = self.gateways.find(g => g.id === temperatureSensor._gateways_id);

          self.temperatureSensors.replace([
            ...self.temperatureSensors.filter(e => e.id !== temperatureSensor.id),
            {
              ...temperatureSensor,
              latest_sample_received_at: received_at,
              latest_sample_age_seconds: (Date.now() - Date.parse(received_at)) / 1000,
              latest_sample_value: sensor_temp_c,
            },
          ]);

          self.updateGateway({
            ...gateway,
            latest_sample_received_at: received_at,
          });
        }
      });
      const sensors = self.getFilteredItemsByKey('sensor');

      const allSensorsOnline = sensors.every(sensor => {
        return (Date.now() - Date.parse(sensor.latest_heartbeat_received_at)) / 1000 < 900;
      });
      const { ui } = getRootStore();
      if (!ui.systemStatus.restored && ui.systemStatus.offline && allSensorsOnline) {
        ui.setSystemRestored(true);

        setTimeout(() => {
          ui.setSystemOffline(false);
        }, 5000);
      }
    },
    setPeriod: flow(function* (period) {
      try {
        self.period = period;
        yield self.fetchDeviceConnectivityHistory();
      } catch (e) {
        return Promise.reject(e);
      }
    }),

    setSelectedGatewayId(id) {
      if (id !== self.selectedGatewayId) {
        self.selectedGatewayId = id;
        self.fetchDeviceConnectivityHistory();
      }
    },

    setConnectivityHistory(result) {
      const deviceTypes = ['gateways', 'brus', 'sensors'];
      self.connectivityHistory = topologyManagementInitialState.connectivityHistory;

      result.forEach(({ from_ts, to_ts, online_devices }) => {
        deviceTypes.forEach(type => {
          Object.entries(online_devices[type]).forEach(([id, isActive]) => {
            self.connectivityHistory[type].push({
              id: Number(id),
              from_ts,
              to_ts,
              isActive,
              date: from_ts,
              value: Number(isActive),
            });
          });
        });
      });

      return self.connectivityHistory;
    },

    setSensorMetaInfo(data) {
      self.selectedSensorMetadata = data;
    },
    getDeviceHistory(type, id) {
      return self.connectivityHistory[type]
        .filter(e => e.id === id)
        .sort((a, b) => Date.parse(a.from_ts) - Date.parse(b.from_ts));
    },
    getPeriodRange() {
      return differenceInDays(new Date(self.period.to), new Date(self.period.from));
    },

    getBruBySensorId(sensorId) {
      return self
        .getFilteredItemsByKey('bru')
        .find(bru => bru.sensors.some(sensor => sensor.id === sensorId));
    },
  }));
