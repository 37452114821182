import { Close, WarningAmber } from '@mui/icons-material';
import { Button, Drawer, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DetailsDrawerCard from 'components/shared/SystemOffline/DetailsDrawer/DetailsDrawerCard';
import { useMemo } from 'react';
import palette from 'theme/palette';
import { useMediaQueries, useSystemOfflineData } from 'hooks';

const DetailsDrawer = ({ isOpen, handleClose, handleNavigateToHardwareManagement }) => {
  const { isTabletOrGreater } = useMediaQueries();
  const { devices } = useSystemOfflineData();

  const deviceList = useMemo(() => {
    return devices.offline
      .filter(device => device.type_id === 0 || device.line !== null || device.line)
      .map(device => {
        if (device.type_id === 0) {
          device.deviceType = 'gateway';
        } else if (device.line !== null) {
          device.deviceType = 'sensor';
        }
        return device;
      });
  }, [devices]);

  return (
    <Drawer
      anchor={isTabletOrGreater ? 'right' : 'bottom'}
      open={isOpen}
      onClose={() => handleClose()}
      sx={{
        '& .MuiDrawer-paper': {
          borderRadius: `${isTabletOrGreater ? '8px 0 0 8px' : '0'}`,
        },
      }}
    >
      <StyledRootDiv>
        <div onClick={() => handleClose()} className={'closeIcon'}>
          <Close />
        </div>
        <div className={'warningIcon'}>
          <WarningAmber htmlColor={palette.status.red} fontSize={'large'} />
        </div>
        <Typography variant={'h5'} className={'title'}>
          Offline Details
        </Typography>
        <Typography variant={'body1'} className={'subTitle'} color={palette.whiteEmphasis.medium}>
          Detected equipment offline:
        </Typography>
        <ul className={'deviceList'}>
          {deviceList.map((device, index) => {
            return (
              <li key={index} className={'deviceListItem'}>
                <DetailsDrawerCard device={device} />
              </li>
            );
          })}
        </ul>
        <div className={'hardwareManagementContainer'}>
          <Button
            onClick={handleNavigateToHardwareManagement}
            variant={'outlined'}
            color={'secondary'}
            className={'button'}
          >
            Hardware Management
          </Button>
        </div>
      </StyledRootDiv>
    </Drawer>
  );
};

export default DetailsDrawer;

const StyledRootDiv = styled('div')(({ theme: { palette } }) => ({
  position: 'relative',
  padding: '16px 16px 0',
  '.closeIcon': {
    backgroundColor: palette.background.paper,
    position: 'sticky',
    paddingTop: '16px',
    marginTop: '-16px',
    top: '0',
    textAlign: 'start',
    width: '100%',
    zIndex: 1,
  },
  '.warningIcon': {
    marginBottom: '12px',
  },
  '.title': {
    marginBottom: '32px',
  },
  '.subTitle': {
    marginBottom: '12px',
  },
  '.deviceList': {
    listStyleType: 'none',
    padding: '0 0 20px',
  },
  '.deviceListItem': {
    marginBottom: '12px',
  },
  '.deviceCard': {
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  '.hardwareManagementContainer': {
    backgroundColor: palette.background.paper,
    position: 'sticky',
    padding: '24px 20px',
    margin: '0px -16px',
    textAlign: 'center',
    bottom: 0,
    boxShadow: '0px -6px 10px 4px rgba(0, 0, 0, 0.15), 0px -2px 3px 0px rgba(0, 0, 0, 0.30)',
    '.button': {
      width: '100%',
    },
  },
}));
