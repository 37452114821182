import React, { useEffect, useRef, useMemo } from 'react';
import { Box, Typography, styled } from '@mui/material';

import Chart from 'chart.js/auto';
import 'chart.js/auto';
import { format } from 'date-fns';

import { observer } from 'mobx-react';

import { useStore } from 'hooks';

import { GraphIcon } from 'assets/icons';

import { dateUtilities } from 'utils';

let chart = null;

const ChartDetails = ({ device = {}, type = 'gateway' }) => {
  const gradients = {
    gateway: ['rgba(255, 255, 255, 0.87)', 'rgba(255, 255, 255, 0.4)', 'rgba(255, 255, 255, 0)'],
    bru: ['rgba(255, 122, 0, 1)', 'rgba(255, 122, 0, 0.5)', 'rgba(255, 122, 0, 0)'],
    sensor: ['rgba(19, 165, 188, 1)', 'rgba(19, 165, 188, 0.5)', 'rgba(19, 165, 188, 0)'],
  };

  const { topologyManagementStore } = useStore();
  const chartRef = useRef({ current: null });
  const ticks_handler = () => {
    const days = topologyManagementStore.getPeriodRange();

    if (days === 1) {
      return 6;
    } else if (days > 1 && days < 15) {
      return days;
    } else if (days > 14 && days < 32) {
      return Math.round(days / 2);
    }
  };

  const chartAxisFormat = () => (topologyManagementStore.getPeriodRange() > 1 ? 'M/d' : 'h:mm a');

  const getGradient = (ctx, chartArea, color) => {
    let width, height, gradient;

    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;

    if (!gradient || width !== chartWidth || height !== chartHeight) {
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient.addColorStop(0, 'transparent');
      gradient.addColorStop(1, color);
    }

    return gradient;
  };

  const dataset = useMemo(
    () => topologyManagementStore.getDeviceHistory(`${type}s`, device.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [device.id, type, topologyManagementStore, topologyManagementStore.isLoaded],
  );

  useEffect(() => {
    chartRef.current = null; // Clear canvas before use chart
    if (chart) {
      chart.destroy();
    }

    if (device.id) {
      const ctx = document.getElementById(`chart_details_canvas_${device.id}`)?.getContext('2d');

      if (ctx) {
        const gradient = ctx.createLinearGradient(0, 0, 0, 200);
        gradient.addColorStop(0, gradients[type][0]);
        gradient.addColorStop(0.22, gradients[type][1]);
        gradient.addColorStop(0.44, gradients[type][2]);

        const labels = dataset?.map(({ date }) => date);

        const data = {
          labels: labels.map(date => format(new Date(date), chartAxisFormat())),
          rawLabels: labels,
          datasets: [
            {
              label: dataset.map(({ isActive, from_ts, to_ts }) => {
                const startPeriod = dateUtilities.formatDateTimeLocal(from_ts);
                const endPeriod = dateUtilities.formatDateTimeLocal(to_ts);
                const status =
                  isActive.pct === 0 ? 'Offline' : isActive.pct > 75 ? 'Online' : 'Degraded';
                return `Connectivity: ${status} (${isActive.pct}%)\nPeriod Start: ${startPeriod}\nPeriod End: ${endPeriod}`;
              }),
              data: dataset.map(({ isActive }) => {
                const { pct } = isActive;
                return pct;
              }),
              fill: 'start',
              stepped: true,
              tension: 0,
              pointRadius: 0,
              borderWidth: 2,
              borderColor: gradients[type][0],
              backgroundColor: function (context) {
                const chart = context.chart;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                  return;
                }
                return getGradient(ctx, chartArea, gradients[type][0]);
              },
            },
          ],
        };

        const config = {
          type: 'line',
          data: data,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  stepSize: 1,
                  callback: function (value, index, ticks) {
                    if (index % Math.round(topologyManagementStore.bins / ticks_handler()) === 0) {
                      return this.getLabelForValue(value);
                    }
                  },
                },
              },
              y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                  stepSize: 25,
                  font: {
                    size: 10,
                  },
                  width: '50px',
                },
                min: 0,
                max: 100,
              },
            },
            interaction: {
              intersect: false,
              axis: 'x',
              mode: 'nearest',
            },
            hover: {
              intersect: false,
              mode: 'nearest',
            },
            plugins: {
              tooltipLine: {},
              title: {
                display: false,
              },
              legend: {
                display: false,
              },
              tooltip: {
                enabled: true,
                position: 'nearest',
                titleFont: {
                  size: 12,
                },
                callbacks: {
                  title: ctx => {
                    const { dataIndex } = ctx[0];
                    return ctx[0].chart.data.datasets[0].label[dataIndex];
                  },
                  label: () => '',
                  beforeLabel: () => '',
                },
              },
            },
          },
        };

        chart = new Chart(ctx, config);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device.id, chartRef.current, dataset]);

  return (
    <StyledRootBox>
      {dataset.length ? (
        <Box sx={{ position: 'relative', width: `100%`, padding: '0 16px' }}>
          <canvas
            ref={chartRef}
            id={`chart_details_canvas_${device.id}`}
            className="chart_details_canvas"
          />
        </Box>
      ) : (
        <Box className="placeholder_box">
          <GraphIcon />
          <Typography variant="h7">No Data</Typography>
          <Typography variant="body2">for selected sensor and/or period</Typography>
        </Box>
      )}
    </StyledRootBox>
  );
};

export default observer(ChartDetails);

const StyledRootBox = styled(Box)(() => ({
  width: '100%',
  '.chart_details_title_box': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '0 5px 5px',

    '.chart_details_title': {
      fontSize: '0 16px',
      color: '#E1E1E1',
    },
    '& > :first-of-type': {
      position: 'absolute',
      left: '30px',
      color: '#A1A1A1',
      fontSize: '12px',
    },

    '& > :last-of-type': {
      position: 'absolute',
      right: '30px',
      color: '#A1A1A1',
      fontSize: '12px',
    },
  },
  '.chart_details_canvas': {
    height: '300px !important',
    position: 'relative',
  },
  '.placeholder_box': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
