import httpClient from 'services/httpClient';
import * as api from 'config/routes';
const { REACT_APP_API_BASE_URL } = process.env;

//TODO remove unnecessary requests and separate by functionality
const API = {
  login(payload) {
    return httpClient.post(api.USER_AUTHENTICATE, payload);
  },
  logout(token) {
    return httpClient.get(api.USER_LOGOUT, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  acceptUserRole(payload, config) {
    return httpClient.post(api.USER_ACCEPT_GRANT, payload, config);
  },
  resetPassword(payload, config) {
    return httpClient.post(api.USER_RESET_PASSWORD, payload, config);
  },
  forgotPassword(params) {
    return httpClient.get(api.USER_FORGOT_PASSWORD, { params });
  },
  getBeverage(id) {
    return httpClient.get(api.BEVERAGE, { requestParams: { id } });
  },
  getLine(line_id) {
    return httpClient.get(api.LINE_STATISTICS, {
      requestParams: { id: line_id },
    });
  },
  getLines() {
    return httpClient.get(api.LINES_STATISTICS);
  },
  getLineTaps(params) {
    return httpClient.get(api.LINE_TAPS, { params });
  },
  getTaps(params) {
    return httpClient.get(api.TAPS, { params });
  },
  patchTap(id, body) {
    return httpClient.patch(api.TAP, body, { requestParams: { id } });
  },
  getItemLine(params) {
    return httpClient.get(api.ITEM_LINES, {
      params,
    });
  },
  getItemLines(params) {
    return httpClient.get(api.ITEM_LINES, { params });
  },
  getContainers(params, config) {
    return httpClient.get(api.CONTAINERS, { params, ...config });
  },
  patchContainer(payload, params, requestParams) {
    return httpClient.patch(api.CONTAINER, payload, {
      params,
      requestParams,
    });
  },
  patchLineTap(payload, requestParams) {
    return httpClient.patch(api.LINE_TAPS, payload, {
      requestParams,
    });
  },
  createTap(body) {
    return httpClient.post(api.TAPS, body);
  },
  changeContainerVolumeUnit(payload) {
    return httpClient.post(api.CHANGE_CONTAINER_UNITS, payload);
  },
  createContainer(payload, params, config) {
    return httpClient.post(api.CONTAINERS, payload, { params, ...config });
  },
  deleteContainer(id) {
    return httpClient.delete(api.CONTAINER, {
      headers: { 'delete-deleted': true },
      requestParams: { id },
    });
  },
  getRoleUsers(params) {
    return httpClient.get(api.ROLE_USERS, { params });
  },
  patchRoleUser(payload, requestParams) {
    return httpClient.patch(api.ROLE_USER, payload, { requestParams });
  },
  getSKUs(params) {
    return httpClient.get(api.SKUS, { params });
  },
  getItemEvents(params) {
    return httpClient.get(api.ITEM_AUDIT_EVENTS, { params });
  },
  getItem(id) {
    return httpClient.get(api.ITEM_STATISTICS, {
      requestParams: { id },
    });
  },
  getItems(params) {
    return httpClient.get(api.ITEMS_STATISTICS, { params });
  },
  addItems(body) {
    return httpClient.post(api.ADD_INVENTORY, body);
  },
  updateItem(id, body) {
    return httpClient.patch(api.ITEM_STATISTICS, body, {
      requestParams: { id },
    });
  },
  patchItem(id, body) {
    return httpClient.patch(api.ITEM, body, {
      requestParams: { id },
    });
  },
  updateItems(body, params) {
    return httpClient.patch(api.ITEMS, body, { params });
  },
  removeItemsFromInventory(body) {
    return httpClient.post(api.REMOVE_INVENTORY, body);
  },
  findProducers(params) {
    return httpClient.get(api.SEARCH_PRODUCERS, { params });
  },
  createProducer(body) {
    return httpClient.post(api.PRODUCERS, body);
  },
  updateBeverage(id, body, params) {
    return httpClient.patch(api.BEVERAGE, body, {
      requestParams: { id },
      params,
    });
  },
  disconnectCurrentItem(body) {
    return httpClient.post(api.DISCONNECT_CURRENT_ITEM, body);
  },
  disconnectCurrentSensors(body) {
    return httpClient.post(api.LINE_DISCONNECT_SENSOR, body);
  },
  disconnectCurrentTap(body) {
    return httpClient.post(api.LINE_DISCONNECT_TAP, body);
  },
  connectNewLineTap(body) {
    return httpClient.post(api.LINE_CONNECT_NEW_TAP, body);
  },
  connectNewLineSensor(body) {
    return httpClient.post(api.LINE_CONNECT_NEW_SENSOR, body);
  },

  connectNewItem(body) {
    return httpClient.post(api.CONNECT_NEW_ITEM, body);
  },
  connectNextItem(body) {
    return httpClient.post(api.LINE_NEXT_ITEM, body);
  },
  reorder(body) {
    return httpClient.post(api.ITEM_LINE_REORDER, body);
  },
  addNewItemToTheFront(params) {
    return httpClient.get(api.ADD_ITEM_TO_FRONT, { params });
  },
  addNewItemToTheBack(params) {
    return httpClient.get(api.ADD_ITEM_TO_BACK, { params });
  },
  removeItemFromTheQueue(params) {
    return httpClient.get(api.REMOVE_ITEM_LINE, { params });
  },
  createSKU(body) {
    return httpClient.post(api.SKUS, body);
  },
  updateSKU(id, body) {
    return httpClient.patch(api.SKUSES, body, { requestParams: { id } });
  },
  deleteSKU(id) {
    return httpClient.delete(api.SKUSES, {
      headers: { 'delete-deleted': true },
      requestParams: { id },
    });
  },
  getSku(id) {
    return httpClient.get(api.SKUSES, { requestParams: { id } });
  },
  getMyBeverages(params) {
    return httpClient.get(api.BEVERAGES, { params });
  },
  searchBeverages(params) {
    return httpClient.get(api.SEARCH_BEVERAGES, { params });
  },
  createBeverage(payload) {
    return httpClient.post(api.BEVERAGES_CREATE, payload);
  },
  copyBeverage(body) {
    return httpClient.post(api.COPY_BEVERAGE, body);
  },
  updateMyBeverage(id, body) {
    return httpClient.patch(api.BEVERAGE, body, {
      requestParams: { id },
    });
  },
  removeMyBeverage(id) {
    return httpClient.delete(api.BEVERAGE, {
      headers: { 'delete-deleted': true },
      requestParams: { id },
    });
  },
  changeUserRole(params) {
    return httpClient.get(api.USER_ROLE_SWITCH, { params });
  },
  getEstablishment(requestParams) {
    return httpClient.get(api.ESTABLISHMENT, { requestParams });
  },
  getIntegrations(requestParams) {
    return httpClient.get(api.INTEGRATIONS, { requestParams });
  },
  patchEstablishment(patch, requestParams) {
    return httpClient.patch(api.ESTABLISHMENT, patch, { requestParams });
  },
  getUnits(params) {
    return httpClient.get(api.UNITS, { params });
  },
  sendReport(data) {
    return httpClient.post(api.SEND_REPORT, data);
  },
  cleanStart(body) {
    return httpClient.post(api.CLEAN_START, body);
  },
  cleanStop(body) {
    return httpClient.post(api.CLEAN_STOP, body);
  },
  cleanModify(body) {
    return httpClient.post(api.CLEAN_MODIFY, body);
  },
  cleanRemove(body) {
    return httpClient.post(api.CLEAN_REMOVE, body);
  },
  cleanSchedule(body) {
    return httpClient.post(api.CLEAN_SCHEDULE, body);
  },
  cleanAdd(body) {
    return httpClient.post(api.CLEAN_ADD, body);
  },
  updateItemLine(id, body) {
    return httpClient.patch(api.ITEM_LINE, body, { requestParams: { id } });
  },
  fetchBusinessHours(params) {
    return httpClient.get(api.BUSINESS_HOURS, { params });
  },
  fetchHappyHours(params) {
    return httpClient.get(api.HAPPY_HOURS, { params });
  },
  fetchEvents(params) {
    return httpClient.get(api.EVENTS, { params });
  },
  fetchSchedules(params) {
    return httpClient.get(api.SCHEDULES, { params });
  },
  fetchScheduleTypes(params) {
    return httpClient.get(api.SCHEDULE_TYPES, { params });
  },
  createSchedule(body) {
    return httpClient.post(api.SCHEDULES, body);
  },
  deleteSchedule(id) {
    return httpClient.delete(api.SCHEDULE, {
      requestParams: {
        id,
      },
    });
  },
  patchSchedule(body, requestParams) {
    return httpClient.patch(api.SCHEDULE, body, { requestParams });
  },
  fixItemItemLines(body) {
    return httpClient.post(api.FIX_ITEM_ITEM_LINES, body);
  },
  applyItemLineFixes(body) {
    return httpClient.post(api.APPLY_ITEM_LINE_FIXES, body);
  },
  itemLinesCorrect(body) {
    return httpClient.post(api.ITEM_LINES_CORRECT, body);
  },
  getPOSSKUs(params) {
    return httpClient.get(api.POS_SKUS, { params });
  },
  patchPOSSKU(id, body) {
    return httpClient.patch(api.POS_SKU, body, { requestParams: { id } });
  },
  patchPOSSKUs(params, body) {
    return httpClient.patch(api.POS_SKUS, body, { params });
  },
  getUsers() {
    return httpClient.get(api.USERS);
  },
  getUser(user_id, { headers }) {
    return httpClient.get(api.USER, {
      requestParams: { id: user_id },
      headers,
    });
  },
  getRoles() {
    return httpClient.get(api.ROLES);
  },
  addUserRole(body) {
    return httpClient.post(api.USER_GRANT, body);
  },
  lockUserRoles(body) {
    return httpClient.patch(api.USER_GRANT, body);
  },
  resendRoleInvitation(body) {
    return httpClient.post(api.USER_RESEND_INVITATION, body);
  },
  cancelRoleInvitation(body) {
    return httpClient.post(api.USER_CANCEL_INVITATION, body);
  },
  updateUser(id, body) {
    return httpClient.patch(api.USER, body, {
      requestParams: { id },
    });
  },
  swapItemOnTheLine(body) {
    return httpClient.post(api.SWAP, body);
  },
  generateReport(body) {
    return httpClient.post(api.GENERATE_REPORT, body, { timeout: 2 * 60 * 1000 });
  },
  getCleanings() {
    return httpClient.get(api.CLEANINGS);
  },
  getCleaningStatistics() {
    return httpClient.get(api.CLEANING_STATISTICS);
  },
  getCleaningsStatistics(params) {
    return httpClient.get(api.CLEANINGS_STATISTICS, { params });
  },
  getCarriers() {
    return httpClient.get(api.CARRIERS);
  },
  patchRoleUserFlags(body) {
    return httpClient.patch(api.ROLE_USERS_FLAG, body);
  },
  getBundle(params) {
    return httpClient.get(api.BUNDLE, { params });
  },
  activateMenu(body) {
    return httpClient.post(api.ACTIVATE_MENU, body);
  },
  getReport() {
    return httpClient.get(api.REPORT);
  },
  patchReport(data, params) {
    return httpClient.patch(api.REPORT, data, { params });
  },
  createMenu(payload, params, config) {
    return httpClient.post(api.MENUS, payload, { params, ...config });
  },
  deleteMenu(id) {
    return httpClient.delete(api.MENU, {
      headers: { 'delete-deleted': true },
      requestParams: { id },
    });
  },
  updateMenu(id, body) {
    return httpClient.patch(api.MENU, body, {
      requestParams: { id },
    });
  },
  createMenuDesign(payload, params, config) {
    return httpClient.post(api.MENU_DESIGNS, payload, { params, ...config });
  },
  deleteMenuDesign(id) {
    return httpClient.delete(api.MENU_DESIGN, {
      headers: { 'delete-deleted': true },
      requestParams: { id },
    });
  },
  updateMenuDesign(id, body) {
    return httpClient.patch(api.MENU_DESIGN, body, {
      requestParams: { id },
    });
  },
  createMenuList(payload, params, config) {
    return httpClient.post(api.MENU_LISTS, payload, { params, ...config });
  },
  deleteMenuList(id) {
    return httpClient.delete(api.MENU_LIST, {
      headers: { 'delete-deleted': true },
      requestParams: { id },
    });
  },
  updateMenuList(id, body) {
    return httpClient.patch(api.MENU_LIST, body, {
      requestParams: { id },
    });
  },
  reorderContainers(payload) {
    return httpClient.post(api.REORDER_CONTAINERS, payload);
  },
  generateQRCode(uuid, params) {
    return httpClient.get(api.GENERATE_MENU_QR_CODE, {
      baseURL: `${REACT_APP_API_BASE_URL}`,
      requestParams: { uuid },
      params,
    });
  },
  getInventoryAnalyticsReport(body) {
    return httpClient.post(api.REPORT_INVENTORY_ANALYTICS, body);
  },
  getInventoryKegRate(body) {
    return httpClient.post(api.GET_INVENTORY_KEG_RATE, body);
  },
  adjustKegLevel(body) {
    return httpClient.post(api.ADJUST_KEG_LEVEL, body);
  },
  getLinesStatistics() {
    return httpClient.get(api.LINES_STATISTICS);
  },
  patchLines(body, params) {
    return httpClient.patch(api.LINES, body, { params });
  },
  patchLinesStatistics(body, params) {
    return httpClient.patch(api.LINES, body, { params });
  },
  createLine(body) {
    return httpClient.post(api.LINES, body);
  },
  patchLine(body, id) {
    return httpClient.patch(api.LINE, body, { requestParams: { id } });
  },
  postCleanResetStatistic(body) {
    return httpClient.post(api.CLEANING_RESET_STATISTICS, body);
  },
  getCoolersStatistics() {
    return httpClient.get(api.COOLERS_STATISTICS);
  },
  getCoolers() {
    return httpClient.get(api.COOLERS);
  },
  createCooler(body) {
    return httpClient.post(api.COOLERS, body);
  },
  patchCooler(body, id) {
    return httpClient.patch(api.COOLER, body, { requestParams: { id } });
  },
  getRoleUserBars() {
    return httpClient.get(api.BARS_ROLE_USERS);
  },
  addUserToBar(body) {
    return httpClient.post(api.BARS_ROLE_USERS, body);
  },
  deleteUserFromBar(id) {
    return httpClient.delete(api.BAR_ROLE_USERS, {
      headers: { 'delete-deleted': true },
      requestParams: { id },
    });
  },
  getGetaways() {
    return httpClient.get(api.GATEWAYS);
  },
  getDeviceHistory(body, params) {
    return httpClient.post(api.DEVICE_HISTORY, body, { params });
  },

  getDeviceConnectivityHistory(params) {
    return httpClient.get(api.DEVICE_CONNECTIVITY_HISTORY, { params });
  },
  getSensorDiagnosticInfo(params) {
    return httpClient.get(api.SENSOR_DIAGNOSTIC_INFO, { params });
  },
  getCoolerBruSummary(body, params) {
    return httpClient.post(api.COOLER_BRU_SUMMARY, body, { params });
  },
  getAlerts() {
    return httpClient.get(api.ALERTS);
  },
  patchAlerts(data, params) {
    return httpClient.patch(api.ALERTS, data, { params });
  },
  patchGateways(data, id) {
    return httpClient.patch(api.GATEWAY, data, { requestParams: { id } });
  },
  patchNotification(data, id) {
    return httpClient.patch(api.NOTIFICATION, data, { requestParams: { id } });
  },
  disableAlarms() {
    return httpClient.post(api.DISABLE_ALARMS, {
      alarms_ids: null,
    });
  },
  patchTemperatureAlert(id, data) {
    return httpClient.patch(api.STATISTIC_TEST, data, { requestParams: { id } });
  },

  replaceBRU(body) {
    return httpClient.post(api.REPLACE_BRU, body);
  },
};

export default API;
