import React from 'react';
import { observer } from 'mobx-react';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import clsx from 'clsx';

import { dateUtilities, numberUtilities } from 'utils';
import { useStore } from 'hooks';

const GeneralTab = () => {
  const { topologyManagementStore } = useStore();
  const handleRounding = val => (val ? numberUtilities.roundToDecimal(val) : 'N/A');

  const {
    signal_strength,
    pga_gain,
    sensor_vfr,
    volume_scale_factor,
    calibrated_tof,
    sensor_temp_c,
  } = topologyManagementStore.selectedSensorMetadata || {};

  return (
    <StyledRootBox className={clsx({ hidden: !topologyManagementStore.selectedSensorMetadata })}>
      <Box className="item">
        <Typography gutterBottom color={({ palette }) => palette.whiteEmphasis.medium} variant="h8">
          Latest Pour
        </Typography>
        <Typography variant="h7">{dateUtilities.formatDateIncludingToday(new Date())}</Typography>
      </Box>

      <Box className="item row">
        <Box className="item_row">
          <Typography
            gutterBottom
            color={({ palette }) => palette.whiteEmphasis.medium}
            variant="h8"
          >
            Signal Strength
          </Typography>
          <Typography variant="h7">{handleRounding(signal_strength)}</Typography>
        </Box>
        <Box className="item_row">
          <Typography
            gutterBottom
            color={({ palette }) => palette.whiteEmphasis.medium}
            variant="h8"
          >
            VolScale
          </Typography>
          <Typography variant="h7">{handleRounding(volume_scale_factor)}</Typography>
        </Box>
      </Box>

      <Box className="item row">
        <Box className="item_row">
          <Typography
            gutterBottom
            color={({ palette }) => palette.whiteEmphasis.medium}
            variant="h8"
          >
            Gain
          </Typography>
          <Typography variant="h7">{handleRounding(pga_gain)}</Typography>
        </Box>
        <Box className="item_row">
          <Typography
            gutterBottom
            color={({ palette }) => palette.whiteEmphasis.medium}
            variant="h8"
          >
            CalTOF
          </Typography>
          <Typography variant="h7">
            {handleRounding(Number(calibrated_tof?.slice(0, calibrated_tof.indexOf('e'))))}
          </Typography>
        </Box>
      </Box>

      <Box className="item row">
        <Box className="item_row">
          <Typography
            gutterBottom
            color={({ palette }) => palette.whiteEmphasis.medium}
            variant="h8"
          >
            VFR
          </Typography>
          <Typography variant="h7">{handleRounding(sensor_vfr)}</Typography>
        </Box>
        <Box className="item_row">
          <Typography
            gutterBottom
            color={({ palette }) => palette.whiteEmphasis.medium}
            variant="h8"
          >
            Temperature
          </Typography>
          <Typography variant="h7">{handleRounding(sensor_temp_c)}</Typography>
        </Box>
      </Box>
    </StyledRootBox>
  );
};

export default observer(GeneralTab);

const StyledRootBox = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',

  '&.hidden': {
    visibility: 'hidden',
  },

  '.item': {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0',
    borderBottom: `1px solid ${palette.secondary.main}`,

    '&.row': {
      flexDirection: 'row',
    },
  },

  '.item_row': {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '50%',
  },
}));
