import { QuestionMark } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Drawer, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { WarningIcon } from 'assets/icons';
import palette from 'theme/palette';
import { observer } from 'mobx-react';

const troubleshootingSteps = [
  {
    title: 'Evaluate Offline Equipment',
    description:
      'Using the Offline Details provided, identify which pieces of equipment need troubleshooting.',
  },
  {
    title: 'Inspect Equipment Connections',
    description:
      'If the system is partially offline, inspect the cable connections between equipment and ensure they are properly connected, otherwise, verify that the Gateway is plugged into a power source.',
  },
  {
    title: 'Power Cycle',
    description:
      'Restart the Gateway by unplugging it, waiting a few seconds, and then plugging it back - this can often resolve temporary issues or glitches. Please note, it may take up to 5-minutes for the Offline Status to update.',
  },
  {
    title: 'Contact Support',
    description:
      "If you're still experiencing difficulties that cannot be resolved through troubleshooting, reach out to your Customer Success Manager for additional assistance.",
  },
];

const SystemOfflinePopup = ({
  title,
  statusMessage,
  isPopupOpen,
  fullSystemOffline,
  offlineDeviceText,
  handlePopupClose,
  handleDetailsClick,
}) => {
  return (
    <Drawer open={isPopupOpen} anchor={'bottom'} onClose={handlePopupClose}>
      <StyledRootDiv className={'SystemOfflinePopup'}>
        <div className={`closeIcon`}>
          <CloseIcon onClick={handlePopupClose} />
        </div>
        <div className={`header`}>
          <WarningIcon />
          <Typography className={'headerTitle'} variant="h5" color={palette.status.red}>
            {title}
          </Typography>
          <Typography
            color={palette.whiteEmphasis.high}
            className={`headerDescription`}
            variant="body1"
          >
            {statusMessage}
          </Typography>
        </div>
        {!fullSystemOffline && (
          <div className={'offlineDevices'}>
            <Typography variant="h6" color={palette.whiteEmphasis.high}>
              {offlineDeviceText}
            </Typography>
            <Button
              className={'detailsButtons'}
              variant={'outlined'}
              color={'secondary'}
              onClick={handleDetailsClick}
            >
              Offline Details
            </Button>
          </div>
        )}
        <div className={'troubleshootTitle'}>
          <QuestionMark className={'questionMarkIcon'} />
          <Typography variant="h5">Troubleshooting</Typography>
        </div>
        <ol className={'troubleshootSteps'}>
          {troubleshootingSteps.map((step, index) => {
            return (
              <li key={index} className={'troubleshootStep'}>
                <div className={'ordinalContainer'}>
                  <Typography variant="h8" className={'ordinal'}>
                    {index + 1}
                  </Typography>
                </div>
                <div>
                  <Typography className={'troubleshootStepTitle'} variant="body1">
                    {step.title}
                  </Typography>
                  <Typography variant="body2">{step.description}</Typography>
                </div>
              </li>
            );
          })}
        </ol>
      </StyledRootDiv>
    </Drawer>
  );
};

const StyledRootDiv = styled('div')(({ theme: { palette, breakpoints, spacing } }) => ({
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  '.closeIcon': {
    textAlign: 'right',
    marginBottom: '16px',
  },
  '.header': {
    textAlign: 'center',
  },
  '.headerDescription': {
    marginTop: '16px',
  },
  '.offlineDevices': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '24px',
  },
  '.detailsButtons': {
    marginTop: '8px',
    width: '100%',
  },
  '.questionMarkIcon': {
    border: '2px solid white',
    borderRadius: '50%',
    marginRight: '12px',
  },
  '.troubleshootTitle': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '32px',
  },
  '.troubleshootSteps': {
    padding: 0,

    '.troubleshootStep': {
      borderBottom: `1px solid ${palette.outline.main}`,
      padding: '24px 0',
      display: 'flex',

      '&:last-child': {
        borderBottom: 'none',
      },
      '.ordinalContainer': {
        marginRight: '24px',
        marginLeft: '4px',
      },
      '.ordinal': {
        color: palette.primary.main,
        backgroundColor: '#2C1F13',
        height: '24px',
        width: '24px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
      },
      '.troubleshootStepTitle': {
        marginBottom: '8px',
      },
    },
  },
}));

export default observer(SystemOfflinePopup);
