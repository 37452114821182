import React from 'react';
import { Drawer, Box, IconButton, Typography, MenuItem, styled } from '@mui/material';
import { Check, Close } from '@mui/icons-material';

import clsx from 'clsx';

import OnlineStatus from 'components/shared/OnlineStatus';

const SelectSensorMenuDrawer = ({ currentSensor, bru, open, onClose, setCurrentSensor }) => {
  return (
    <StyledRootDrawer open={open} onClose={onClose} anchor="bottom">
      <Box className="title_box">
        <IconButton onClick={onClose} className="close_button">
          <Close />
        </IconButton>
        <Typography variant="h7">Select Sensor</Typography>
      </Box>
      <Box className="body_box">
        {bru?.sensors.map(sensor => (
          <MenuItem
            component={Box}
            className="menu_box"
            onClick={() => {
              setCurrentSensor(sensor);
              onClose();
            }}
            key={sensor.id}
          >
            <Box display="flex" flexDirection="column" justifyContent="space-between">
              <Box display="flex" flexDirection="row" alignItems="center">
                <OnlineStatus
                  date={sensor.latest_heartbeat_received_at}
                  replaced={bru.replacementDetected}
                />
                <Typography>Sensor #{sensor.bru_sensor_address}</Typography>
              </Box>
            </Box>
            <Check className={clsx('check_icon', { active: currentSensor?.id === sensor.id })} />
          </MenuItem>
        ))}
      </Box>
    </StyledRootDrawer>
  );
};

export default SelectSensorMenuDrawer;

const StyledRootDrawer = styled(Drawer)(({ theme: { palette } }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: palette.background.default,
    borderRadius: '16px 16px 0 0',
  },

  '.title_box': {
    padding: '20px 16px',
    borderBottom: `1px solid ${palette.outline.main}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    '.close_button': {
      position: 'absolute',
      left: '16px',
    },

    '.connection_icon': {
      '&.active': {
        fill: palette.status.green,
      },
    },
  },

  '.body_box': {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 20px 20px',

    '.menu_box': {
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '20px 0px',
      borderBottom: `1px solid ${palette.outline.main}`,
    },

    '.status_icon': {
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      marginRight: '10px',
      backgroundColor: palette.status.red,

      '&.active': {
        backgroundColor: palette.status.green,
      },
    },

    '.check_icon': {
      display: 'none',
      fill: palette.primary.main,

      '&.active': {
        display: 'block',
      },
    },
  },
}));
