import { types } from 'mobx-state-tree';

const DeviceModel = types.model({
  id: types.identifierNumber,
  from_ts: types.string,
  to_ts: types.string,
  isActive: types.model({
    pct: types.number,
    seen: types.number,
    expected: types.number,
  }),
  date: types.string,
});

export const DeviceConnectivityHistory = types.model({
  gateways: types.array(DeviceModel),
  brus: types.array(DeviceModel),
  sensors: types.array(DeviceModel),
});
