import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Paper, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { format, subDays, isToday } from 'date-fns';

import clsx from 'clsx';
import { useStore } from 'hooks';

import CalendarDrawer from './CalendarDrawer';

const PeriodButtonGroup = ({ className }) => {
  const { topologyManagementStore } = useStore();

  const [activePeriod, setActivePeriod] = useState('1');
  const [openCalendarDrawer, setOpenCalendarDrawer] = useState(false);

  const handleChangePeriod = event => {
    setActivePeriod(event.target.value);
    topologyManagementStore.setPeriod({
      from: format(subDays(new Date(), event.target.value), 'yyyy-MM-dd HH:mm:ss'),
      to: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    });
  };

  const isActive = value => {
    return activePeriod === value;
  };

  const handleSelectCustom = event => {
    setActivePeriod('custom');
    setOpenCalendarDrawer(true);
  };

  const handleConfirmCustomPeriod = period => {
    topologyManagementStore.setPeriod({
      from: format(new Date(period.from), 'yyyy-MM-dd HH:mm:ss'),
      to: format(isToday(period.to) ? new Date() : new Date(period.to), 'yyyy-MM-dd HH:mm:ss'),
    });
  };

  useEffect(() => {
    return () => handleChangePeriod({ target: { value: '1' } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledRootPaper elevation={0} className={className}>
      <Button
        value={1}
        className={clsx('period_button', { active: isActive('1') })}
        onClick={handleChangePeriod}
      >
        24H
      </Button>
      <Button
        value={7}
        className={clsx('period_button', { active: isActive('7') })}
        onClick={handleChangePeriod}
      >
        7D
      </Button>
      <Button
        value={14}
        className={clsx('period_button', { active: isActive('14') })}
        onClick={handleChangePeriod}
      >
        14D
      </Button>
      <Button
        value={30}
        className={clsx('period_button', { active: isActive('30') })}
        onClick={handleChangePeriod}
      >
        30D
      </Button>
      <Button
        value={'custom'}
        className={clsx('period_button', { active: isActive('custom') })}
        onClick={handleSelectCustom}
      >
        Custom
      </Button>

      <CalendarDrawer
        open={openCalendarDrawer}
        onClose={() => setOpenCalendarDrawer(false)}
        onApply={handleConfirmCustomPeriod}
      />
    </StyledRootPaper>
  );
};

export default observer(PeriodButtonGroup);

const StyledRootPaper = styled(Paper)(({ theme: { palette } }) => ({
  borderRadius: '12px',
  padding: '2px',
  height: 'fit-content',

  '.period_button': {
    borderRadius: '8px',
    height: '24px',
    textTransform: 'uppercase',
    fontSize: '10px',
    color: palette.whiteEmphasis.medium,
    minWidth: '70px',

    '&.active': {
      backgroundColor: palette.background.overlay8,
      color: palette.whiteEmphasis.high,
    },

    '&:not(:last-of-type) ': {
      marginRight: '5px',
    },
  },
}));
