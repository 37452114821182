import { PeopleAlt } from '@mui/icons-material';

import { JarIcon, CleanIcon, FiltersIcon, KegIcon, ReportsIcon } from 'assets/icons';
import { uniq } from 'lodash';

const itemEventsStatuses = {
  0: 'Added',
  11: 'Sold',
  12: 'Expired',
  13: 'Empty',
  14: 'Mistake',
  15: 'Infected',
  7: 'Kicked',
  5: 'Tapped',
  2: 'Saved',
  10: 'De-Queued',
  6: 'Queued',
  16: 'Swapped',
  line_disconnect_current_item: 'Un-Tapped Keg',
  line_next_item: 'Tapped Keg',
  line_connect_new_item: 'Tapped Keg',
  remove_items: 'Removed Inventory',
  add_items: 'Added Inventory',
  adjust_keg_level: 'Keg Level Adjusted',
  updateGenericElement: 'Modified Keg Details',
};

export const icon = {
  bars_role_users: <PeopleAlt />,
  beverages: <JarIcon />,
  cleanings: <CleanIcon />,
  containers: <FiltersIcon />,
  coolers: <FiltersIcon />,
  establishments: <FiltersIcon />,
  gateways: <FiltersIcon />,
  items: <KegIcon />,
  line_sensors: <FiltersIcon />,
  lines: <FiltersIcon />,
  pos_skus: <ReportsIcon />,
  producers: <JarIcon />,
  report_configurations: <ReportsIcon />,
  role_users: <PeopleAlt />,
  skus: <JarIcon />,
  taps: <KegIcon />,
  users: <PeopleAlt />,
};

export const eventTypeMap = {
  bars_role_users: 'Employees',
  beverages: 'Beverages',
  cleanings: 'Cleanings',
  containers: 'Containers',
  coolers: 'Coolers',
  establishments: 'System',
  gateways: 'System',
  items: 'Inventory',
  item_lines: 'Inventory',
  line_sensors: 'System',
  lines: 'System',
  pos_skus: 'Reports',
  producers: 'Beverages',
  report_configurations: 'Reports',
  role_users: 'Employees',
  skus: 'Containers',
  taps: 'System',
  users: 'Employees',
};

export const appliedToFieldMap = {
  users: 'display_name',
  containers: 'name',
  producers: 'name',
  establishments: 'name',
  skus: 'name',
  pos_skus: 'item',
  lines: 'line_identifier',
  taps: 'identifier',
  coolers: 'name',
};

export const changeTypeTitle = {
  I: 'Created By',
  U: 'Updated By',
  D: 'Removed By',
};

export const events = {
  bars_role_users: {
    generic_insert: 'Modified Employee Access',
    generic_delete: 'Modified Employee Access',
    deleteGenericElement: 'Modified Employee Access',
    deleteGenericCollection: 'Modified Employee Access',
  },
  beverages: {
    I: {
      add_items: 'Beverage Created',
      copy_beverages: 'Beverage Added',
      create_beverages: 'Beverage Created',
    },
    U: {
      updateGenericElement: 'Beverage Details Modified',
    },
    // generic_update: 'Modified Beverage Details',
    // updateGenericCollection: 'Modified Beverage Details',
  },
  cleanings: {
    I: {
      clean_add: 'Line Cleaning Added',
      clean_modify: 'Modified Line Cleaning log(s)',
      clean_start: 'Line Cleaning Started',
    },
    U: {
      clean_add: 'Line Cleaning Modified',
      clean_modify: 'Line Cleaning Modified',
      clean_stop: 'Line Cleaning Stopped',
    },
    D: {
      clean_add: 'Line Cleaning Removed',
      clean_modify: 'Line Cleaning Removed',
      clean_remove: 'Line Cleaning Removed',
    },
  },
  containers: {
    I: {
      createGeneric: 'Container Created',
      serving_size_created: 'Serving Size Created',
      keg_size_created: 'Keg Size Created',
    },
    U: {
      change_container_units: 'Container Units Modified',
      createGeneric: 'Container Details Modified',
      updateGenericElement: 'Container Details Modified',
      serving_size_modified: 'Serving Size Modified',
      keg_size_modified: 'Keg Size Modified',
    },
    D: {
      deleteGenericElement: 'Container Deleted',
      serving_size_removed: 'Serving Size Removed',
      keg_size_removed: 'Keg Size Removed',
    },
  },
  establishments: {
    U: {
      updateGenericElement: 'System Configuration Modified',
    },
  },
  items: {
    U: {
      adjust_keg_level: 'Keg Level Adjusted',
      keg_correction_tapped: 'Keg Correction (Tapped)',
      keg_correction_untapped: 'Keg Correction (Un-Tapped)',
      line_connect_new_item: 'Keg Tapped',
      line_disconnect_current_item: 'Keg Un-Tapped',
      line_next_item: 'Keg Tapped',
      remove_items: 'Inventory Removed',
      updateGenericCollection: 'Keg Details Modified',
      updateGenericElement: 'Keg Details Modified',
    },
    I: {
      add_items: 'Inventory Added',
    },
  },
  item_lines: {
    itemline_remove: 'Removed Keg from Queue',
    itemline_reorder: 'Modified Keg Queue',
    line_next_item: 'Tapped Keg',
    line_disconnect_current_item: 'Un-Tapped Keg',
  },
  lines: {
    U: {
      clean_reset_statistics: 'Cleaning Statistics Reset',
      clean_schedule: 'Cleaning Scheduled',
      updateGenericCollection: 'Line Details Modified',
      updateGenericElement: 'Line Details Modified',
      clean_start: 'Line Cleaning Started',
      clean_stop: 'Line Cleaning Stopped',
      clean_add: 'Line Cleaning Added',
      clean_modify: 'Line Cleaning Modified',
    },
    I: {
      createGeneric: 'Line Created',
    },
  },
  line_sensors: {
    I: {
      line_connect_new_sensor: 'Sensor Connected',
    },
    U: {
      line_disconnect_current_sensor: 'Sensor Disconnected',
    },
  },
  taps: {
    U: {
      line_connect_new_tap: 'Tap Connected',
      updateGenericElement: 'Tap Details Modified',
      line_disconnect_current_tap: 'Tap Disconnected',
    },
    I: {
      createGeneric: 'Tap Created',
    },
  },
  pos_skus: {
    U: {
      pos_skus_mapped: 'POS SKU(s) Mapped',
      pos_skus_unmapped: 'POS SKU(s) Un-Mapped',
      pos_skus_unignored: 'POS SKU(s) Un-Ignored',
      pos_skus_modified: 'POS SKU(s) Modified',
      pos_skus_ignored: 'POS SKU(s) Ignored',
    },
  },
  producers: {
    I: {
      createGeneric: 'Producer Created',
    },
  },
  skus: {
    I: {
      add_items: 'Keg Size Created',
      createGeneric: 'Serving/Keg Size Added',
      serving_size_created: 'Serving Size Created',
      keg_size_created: 'Keg Size Created',
    },
    D: {
      serving_size_removed: 'Serving Size Removed',
      keg_size_removed: 'Keg Size Removed',
    },
    U: {
      updateGenericElement: 'Serving/Keg Size Modified',
      serving_size_modified: 'Serving Size Modified',
      keg_size_modified: 'Keg Size Modified',
    },
  },
  role_users: {
    U: {
      updateGenericElement: 'Employee Role Modified',
      user_accept_grant: 'Invite Accepted',
      user_lock_grant: 'Employee Access Modified',
      user_resend_grant: 'Re-Sent Invite',
    },
    I: {
      user_create_grant: 'Employee Invited',
    },
    D: {
      user_delete_role: 'Employee Access Modified',
    },
  },
  coolers: {
    U: {
      updateGenericElement: 'Cooler Details Modified',
    },
  },
  report_configurations: {
    U: {
      updateGenericCollection: 'Report Configuration Updated',
      user_lock_grant: 'Delivery Recipient Removed',
      report_recipients_modified: 'Report Recipients Modified',
      report_configuration_modified: 'Report Configuration Modified',
    },
    I: {
      createGeneric: 'Report Configuration Created',
    },
    D: {
      report_recipients_removed: 'Report Recipient Removed',
    },
  },
  gateways: {
    U: {
      updateGenericElement: 'Gateway Details Modified',
      updateGenericCollection: 'Gateway Details Modified',
    },
  },
};

export const allowed_fields = [
  'name',
  'abv',
  'ibu',
  'type',
  'style',
  'keywords',
  'description',
  'ingredients',
  'metadata',
  'cleaned_from',
  'cleaned_to',
  'volume_total',
  'volume_unit_id',
  'display_status',
  'cooler_id',
  'installation_location',
  'network_connectivity_mode',
  'status_code',
  'unit_cost',
  'initial_volume_total',
  'unit_revenue_goal',
  'container_id',
  'use_by',
  'scheduled_cleaning_at',
  'line_identifier',
  'tap_identifier',
  'geometries',
  'choker',
  'glycol',
  'fob_installed',
  'locked',
];

// Handler for more complex events
export const handleUnusualEvents = itemEvent => {
  if (itemEvent.collection_name === 'pos_skus') {
    let keys = [];
    if (itemEvent?.change_before?.keys()) {
      keys.push(...Array.from(itemEvent?.change_before?.keys() || []));
    }
    if (itemEvent?.change_after?.keys()) {
      keys.push(...Array.from(itemEvent?.change_after?.keys() || []));
    }

    if (uniq(keys).includes('sku_id')) {
      return itemEvent?.change_before?.get('sku_id')
        ? { ...itemEvent, operation: 'pos_skus_unmapped', change_type: 'U' }
        : itemEvent?.change_after?.get('sku_id')
        ? { ...itemEvent, operation: 'pos_skus_mapped', change_type: 'U' }
        : { ...itemEvent, operation: 'pos_skus_modified', change_type: 'U' };
    }

    if (uniq(keys).includes('ignore')) {
      return itemEvent?.change_before?.get('ignore')
        ? { ...itemEvent, operation: 'pos_skus_unignored', change_type: 'U' }
        : { ...itemEvent, operation: 'pos_skus_ignored', change_type: 'U' };
    }
  } else if (itemEvent.operation === 'applyItemLineFixes') {
    return itemEvent?.change_after?.get('status_code') === 5
      ? { ...itemEvent, operation: 'keg_correction_tapped', change_type: 'U' }
      : { ...itemEvent, operation: 'keg_correction_untapped', change_type: 'U' };
  } else if (itemEvent.collection_name === 'report_configurations') {
    if (itemEvent.operation === 'updateGenericCollection') {
      let keys = [];
      if (itemEvent?.changed_keys?.keys()) {
        keys.push(...Array.from(itemEvent?.change_before?.keys() || []));
      }

      if (uniq(keys).includes('delivery_info')) {
        return { ...itemEvent, operation: 'report_recipients_modified', change_type: 'U' };
      } else {
        return { ...itemEvent, operation: 'report_configuration_modified', change_type: 'U' };
      }
    } else if (itemEvent.operation === 'user_lock_grant') {
      return { ...itemEvent, operation: 'report_recipients_removed', change_type: 'D' };
    }
  } else if (['skus', 'containers'].includes(itemEvent.collection_name)) {
    const typeMapping = {
      skus: {
        0: 'keg_size',
        1: 'serving_size',
      },
      containers: {
        1: 'keg_size',
        4: 'serving_size',
      },
    };

    const typeCode = itemEvent?.operation.includes('delete')
      ? itemEvent?.change_before?.get('type_code')
      : itemEvent?.change_after?.get('type_code');

    const operation = itemEvent.operation;
    const collectionMapping = typeMapping[itemEvent.collection_name];

    if (operation === 'createGeneric') {
      return {
        ...itemEvent,
        operation: `${collectionMapping[typeCode]}_created`,
        change_type: 'I',
      };
    } else if (operation === 'updateGenericElement') {
      return {
        ...itemEvent,
        operation: `${collectionMapping[typeCode] ?? 'serving_size'}_modified`,
        change_type: 'U',
      };
    } else if (['deleteGeneric', 'deleteGenericElement'].includes(operation)) {
      return {
        ...itemEvent,
        operation: `${collectionMapping[typeCode]}_removed`,
        change_type: 'D',
      };
    } else if (operation === 'change_container_units') {
      return {
        ...itemEvent,
        operation: `keg_size_modified`,
        change_type: 'U',
      };
    }
  }

  return itemEvent;
};

export const generateEvent = itemEvent => {
  return itemEvent
    ? events[itemEvent.collection_name]?.[itemEvent.change_type]?.[itemEvent.operation] ||
        (() => {
          // Helps find unhandled events
          // console.log(
          //   'unhandled itemEvent',
          //   itemEvent.collection_name,
          //   itemEvent.change_type,
          //   itemEvent.operation,
          // );
          // if event not handled - display operation to avoid errors
          return itemEvent.operation;
        })()
    : '-';
};

export default itemEventsStatuses;
