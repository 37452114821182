import { types } from 'mobx-state-tree';

export const Sensor = types.model({
  id: types.integer,

  archived: types.boolean,
  bru_id: types.maybeNull(types.integer),
  bru_sensor_address: types.maybeNull(types.integer),
  enumerated: types.boolean,
  enumerated_at: types.string,
  firmware_version: types.maybeNull(types.integer),
  hardware_version: types.maybeNull(types.integer),
  latest_heartbeat_id: types.maybeNull(types.integer),
  latest_heartbeat_received_at: types.maybeNull(types.string),
  latest_pour_id: types.maybeNull(types.integer),
  latest_pour_poured_at: types.maybeNull(types.string),
  latest_pour_received_at: types.maybeNull(types.string),
  type: types.maybeNull(types.integer),
  _gateways_establishment_id: types.maybeNull(types.integer),
  _gateways_id: types.maybeNull(types.integer),
});
