import React, { useEffect, useRef, useMemo } from 'react';
import { observer } from 'mobx-react';

import clsx from 'clsx';

import { Box, Typography, styled } from '@mui/material';

import { format } from 'date-fns';

import Chart from 'chart.js/auto';
import 'chart.js/auto';

import { useStore } from 'hooks';
import { dateUtilities } from 'utils';
import { GraphIcon } from 'assets/icons';

import Loader from 'components/shared/Loader';

import { gradients } from './settings';

let chart = null;

const ChartDetails = ({ device = {}, type, loading = false }) => {
  const { topologyManagementStore } = useStore();
  const chartRef = useRef({ current: null });

  const dataset = useMemo(
    () => topologyManagementStore.getDeviceHistory(type, device?.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [device?.id, topologyManagementStore, topologyManagementStore.isLoaded],
  );

  const ticks_handler = () => {
    const days = topologyManagementStore.getPeriodRange();

    if (days === 1) {
      return 6;
    } else if (days > 1 && days < 15) {
      return days;
    } else if (days > 14 && days < 32) {
      return Math.round(days / 2);
    }
  };

  const chartAxisFormat = () => (topologyManagementStore.getPeriodRange() > 1 ? 'M/d' : 'h:mm a');

  const getGradient = (ctx, chartArea, color) => {
    let width, height, gradient;

    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;

    if (!gradient || width !== chartWidth || height !== chartHeight) {
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient.addColorStop(0, 'transparent');
      gradient.addColorStop(1, color);
    }

    return gradient;
  };

  useEffect(() => {
    chartRef.current = null; // Clear canvas before use chart
    if (chart) {
      chart.destroy();
    }

    if (device.id) {
      const ctx = document.getElementById(`chart_details_canvas_${device.id}`)?.getContext('2d');

      if (ctx) {
        const gradient = ctx.createLinearGradient(0, 0, 0, 200);
        gradient.addColorStop(0, gradients[type][0]);
        gradient.addColorStop(0.22, gradients[type][1]);
        gradient.addColorStop(0.44, gradients[type][2]);

        const labels = dataset?.map(({ date }) => date);

        const data = {
          labels: labels.map(date => format(new Date(date), chartAxisFormat())),
          rawLabels: labels,
          datasets: [
            {
              data: dataset.map(({ isActive }) => isActive?.pct || 0),
              fill: 'start',
              stepped: true,
              tension: 0,
              pointRadius: 0,
              borderWidth: 2,
              borderColor: gradients[type][0],
              backgroundColor: function (context) {
                const chart = context.chart;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                  return;
                }
                return getGradient(ctx, chartArea, gradients[type][0]);
              },
            },
          ],
        };

        const config = {
          type: 'line',
          data: data,
          options: {
            responsive: true,
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  stepSize: 1,
                  callback: function (value, index) {
                    if (index % Math.round(topologyManagementStore.bins / ticks_handler()) === 0) {
                      return this.getLabelForValue(value);
                    }
                  },
                },
              },
              y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                  stepSize: 25,
                  font: {
                    size: 10,
                  },
                  width: '50px',
                },
                min: 0,
                max: 100,
              },
            },
            interaction: {
              intersect: false,
              mode: 'nearest',
            },

            hover: {
              intersect: true,
              mode: 'point',
            },

            plugins: {
              tooltipLine: {},
              title: {
                display: false,
              },
              legend: {
                display: false,
              },
              tooltip: {
                enabled: true,
                position: 'nearest',
                titleFont: {
                  size: 12,
                },
                callbacks: {
                  title: ctx => {
                    const startPeriod = dateUtilities.formatDateTimeLocal(
                      dataset[ctx[0].dataIndex].from_ts,
                    );
                    const endPeriod = dateUtilities.formatDateTimeLocal(
                      dataset[ctx[0].dataIndex].to_ts,
                    );
                    const status = ctx[0].raw > 50 ? 'Online' : 'Offline';
                    return `Connectivity: ${status} (${ctx[0].raw}%)\n Period Start: ${startPeriod}\n Period End: ${endPeriod}`;
                  },
                  label: () => '',
                  beforeLabel: () => '',
                },
              },
            },
          },
        };

        chart = new Chart(ctx, config);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device?.id, chartRef.current, dataset]);

  return (
    <StyledRootBox>
      {dataset.length ? (
        <Box
          className={clsx('canvas_wrapper', {
            hidden: loading,
          })}
        >
          <canvas
            ref={chartRef}
            id={`chart_details_canvas_${device.id}`}
            className="chart_details_canvas"
          />
        </Box>
      ) : (
        <Box className="placeholder_box">
          <GraphIcon />
          <Typography variant="h7">No Data Available</Typography>
          <Typography variant="body2">
            for the selected equipment during the requested time period
          </Typography>
        </Box>
      )}
      <Loader
        className={clsx({
          hidden: !loading,
        })}
        centered
        progressMessage="Loading Connectivity History ..."
        indent={0}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          height: 'auto',
        }}
      />
    </StyledRootBox>
  );
};

export default observer(ChartDetails);

const StyledRootBox = styled(Box)(() => ({
  position: 'relative',

  '.hidden': {
    visibility: 'hidden',
  },

  '.canvas_wrapper': {
    position: 'relative',
    width: `100%`,
    paddingTop: '16px',
  },

  '.chart_details_canvas': {
    height: '200px !important',
    width: '100% !important',
    '&.landscape': {
      height: 'calc(100vh - 90px) !important',
    },
  },
  '.placeholder_box': {
    width: '100%',
    position: 'absolute',
    top: '200px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
