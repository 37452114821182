import React from 'react';
import { Box, styled } from '@mui/material';
import { DateRange } from 'react-date-range';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { observer } from 'mobx-react';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { subDays } from 'date-fns';

import palette from 'theme/palette';

const DatePicker = ({ startDate, endDate, handleRangeChange }) => {
  const iconUrl = URL.createObjectURL(new Blob([KeyboardArrowDownIcon], { type: 'image/svg+xml' }));
  const minDate = subDays(new Date(), 90);

  return (
    <StyledRootBox icon={iconUrl}>
      <Box>
        <DateRange
          ranges={[
            {
              startDate: startDate,
              endDate: endDate,
              key: 'selection',
            },
          ]}
          onChange={handleRangeChange}
          moveRangeOnFirstSelection={false}
          editableDateInputs={true}
          showSelectionPreview={false}
          showDateDisplay={false}
          rangeColors={[palette.primary.main]}
          color={palette.primary.main}
          minDate={minDate}
          maxDate={new Date()}
        />
      </Box>
    </StyledRootBox>
  );
};

export default observer(DatePicker);

const StyledRootBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px',
  '& .rdrCalendarWrapper': {
    background: 'transparent',
  },
  '& .rdrMonthAndYearPickers select ': {
    color: theme.palette.white,
  },
  '& .rdrDayPassive': {
    visibility: 'hidden',
  },
  '& .rdrDayDisabled': {
    background: 'transparent',
    opacity: 0.5,
  },
  '& .rdrMonthAndYearPickers select': {
    backgroundImage: `url(${({ icon }) => icon})`,
  },
  '& .rdrDayNumber span': {
    color: theme.palette.white,
  },
  '& .rdrMonth': {
    background: 'transparent',
    borderRadius: '8px',
  },
  '& .rdrNextPrevButton': {
    background: '#2f2f2f',
  },
  '& .rdrNextButton i': {
    borderColor: `transparent transparent transparent ${theme.palette.whiteEmphasis.medium}`,
  },
  '& .rdrPprevButton i': {
    borderColor: `transparent ${theme.palette.whiteEmphasis.medium} transparent transparent`,
  },
}));
