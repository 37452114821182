import DiagnosticInformationDialog from 'components/@SystemConfiguration/HardwareManagement/Desktop/DiagnosticInformationDialog';
import SensorDetailsDrawer from 'components/@SystemConfiguration/HardwareManagement/Mobile/SensorDetailsDrawer';
import { useMediaQueries } from 'hooks';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

const DiagnosticDetails = ({ isOpen, handleClose, type, cooler, devices }) => {
  const { isTabletOrGreater } = useMediaQueries();

  const selectedSensor = useMemo(() => {
    switch (type) {
      case 'gateway': {
        const [bru] = devices.gateway.brus;
        const [sensor] = bru.sensors;
        return sensor;
      }
      case 'bru': {
        const [sensor] = devices.bru.sensors;
        return sensor;
      }
      default: {
        return devices.sensor;
      }
    }
  }, [type, devices]);

  return (
    <>
      {isTabletOrGreater ? (
        <DiagnosticInformationDialog
          open={isOpen}
          onClose={handleClose}
          type={type}
          cooler={cooler}
          devices={devices}
        />
      ) : (
        <SensorDetailsDrawer sensor={selectedSensor} open={isOpen} onClose={handleClose} />
      )}
    </>
  );
};

export default observer(DiagnosticDetails);
