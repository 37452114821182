import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import permissions from 'services/permissions';

import PublicRoute from 'routes/PublicRoute';
import PrivateRoute from 'routes/PrivateRoute';
import Root from 'components/Root';

import { getRootStore } from 'models/root';
import BarTrackAdmin from '../components/@Reports/Configuration/BarTrackAdmin/BarTrackAdmin';
import { commonUtilities } from 'utils';

const AuthLayout = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/layouts/AuthLayout')),
);
const Login = lazy(() => commonUtilities.lazyRetry(() => import('pages/Login')));
const LoginSuccess = lazy(() => commonUtilities.lazyRetry(() => import('pages/LoginSuccess')));
const Registration = lazy(() => commonUtilities.lazyRetry(() => import('pages/Registration')));
const ForgotPassword = lazy(() => commonUtilities.lazyRetry(() => import('pages/ForgotPassword')));
const ForgotPasswordSuccess = lazy(() =>
  commonUtilities.lazyRetry(() => import('pages/ForgotPasswordSuccess')),
);
const ResetPassword = lazy(() => commonUtilities.lazyRetry(() => import('pages/ResetPassword')));
const ResetPasswordSuccess = lazy(() =>
  commonUtilities.lazyRetry(() => import('pages/ResetPasswordSuccess')),
);
const OnTap = lazy(() => commonUtilities.lazyRetry(() => import('pages/OnTap')));
const TapKegQueueSelection = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@TapDetails/TapKegQueueSelection')),
);
const TapChangeKeg = lazy(() => commonUtilities.lazyRetry(() => import('pages/TapChangeKeg')));
const SearchBeverage = lazy(() => commonUtilities.lazyRetry(() => import('pages/SearchBeverage')));
const Inventory = lazy(() => commonUtilities.lazyRetry(() => import('pages/Inventory')));
const Cooler = lazy(() => commonUtilities.lazyRetry(() => import('components/@Inventory/Cooler')));
const Ordering = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@Inventory/Ordering')),
);
const History = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@Inventory/History')),
);
const DigitalMenuLayout = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/layouts/DigitalMenuLayout')),
);
const MenuHome = lazy(() => commonUtilities.lazyRetry(() => import('pages/MenuHome')));
const MenuLists = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@MenuConfiguration/MenuLists')),
);
const MenuDesigns = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@MenuConfiguration/MenuDesigns')),
);
const Settings = lazy(() => commonUtilities.lazyRetry(() => import('pages/Settings')));
const MyBeverages = lazy(() => commonUtilities.lazyRetry(() => import('pages/MyBeverages')));

const AddBeverage = lazy(() => commonUtilities.lazyRetry(() => import('pages/AddBeverage')));
const QualityManagement = lazy(() =>
  commonUtilities.lazyRetry(() => import('pages/QualityManagement')),
);
const SystemHealth = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@QualityManagement/SystemHealth')),
);
const CleaningManagement = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@QualityManagement/CleaningManagement')),
);
const CleaningLogs = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@QualityManagement/CleaningLogs')),
);
const DraftSystemConfig = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@QualityManagement/DraftSystemConfig')),
);
const Reports = lazy(() => commonUtilities.lazyRetry(() => import('pages/Reports')));
const KegCorrection = lazy(() => commonUtilities.lazyRetry(() => import('pages/KegCorrection')));
const SystemConfiguration = lazy(() =>
  commonUtilities.lazyRetry(() => import('pages/SystemConfiguration')),
);
const GeneralSettings = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@SystemConfiguration/GeneralSettings')),
);
const HardwareManagement = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@SystemConfiguration/HardwareManagement')),
);
const AlertsSettings = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@SystemConfiguration/AlertsSettings')),
);
const BarManagerLayout = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/layouts/BarManagerLayout')),
);
const BarSKUManager = lazy(() => commonUtilities.lazyRetry(() => import('pages/BarSKUManager')));
const UnmatchedMapperTable = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@BarSKUManager/UnmatchedMapperTable')),
);
const MatchedMapperTable = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@BarSKUManager/MatchedMapperTable')),
);
const IgnoredTable = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@BarSKUManager/IgnoredTable')),
);
const ActivityLogs = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@BarActivityLogs')),
);
const TapSwapKeg = lazy(() => commonUtilities.lazyRetry(() => import('pages/TapSwapKeg')));
const EndUserServicesAgreement = lazy(() =>
  commonUtilities.lazyRetry(() => import('pages/EndUserServicesAgreement')),
);
const ContactUs = lazy(() => commonUtilities.lazyRetry(() => import('pages/ContactUs')));
const MyAccount = lazy(() => commonUtilities.lazyRetry(() => import('pages/MyAccount')));
const AccountInfo = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@MyAccount/AccountInfo')),
);
const Notifications = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@MyAccount/Notifications')),
);
const ForceLogout = lazy(() => commonUtilities.lazyRetry(() => import('pages/ForceLogout')));
const PageNotFound = lazy(() => commonUtilities.lazyRetry(() => import('pages/PageNotFound')));
const GeneratedReport = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@Reports/Generate')),
);

const ReportConfiguration = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@Reports/Configuration')),
);
const MobileConfiguration = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@Reports/Configuration/MobileConfiguration')),
);

const DeliveryRecipients = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@Reports/Configuration/DeliveryRecipients')),
);
const Thresholds = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@Reports/Configuration/Thresholds')),
);
const Preferences = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@Reports/Configuration/Preferences')),
);
const BusinessHoursMobileWrapper = lazy(() =>
  commonUtilities.lazyRetry(() =>
    import('components/@Reports/Configuration/MobileConfiguration/BusinessHours'),
  ),
);

const Employees = lazy(() => commonUtilities.lazyRetry(() => import('pages/Employees')));
const AddBulkInventory = lazy(() =>
  commonUtilities.lazyRetry(() => import('pages/AddBulkInventory')),
);

const { userStore } = getRootStore();

const config = [
  {
    path: '/auth',
    element: (
      <PublicRoute disableForAuthenticatedUsers>
        <AuthLayout />
      </PublicRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="login" replace />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'login/success',
        element: <LoginSuccess />,
      },
      {
        path: 'accept',
        element: <Registration />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'forgot-password/success',
        element: <ForgotPasswordSuccess />,
      },
      {
        path: 'reset',
        element: <ResetPassword />,
      },
      {
        path: 'reset/success',
        element: <ResetPasswordSuccess />,
      },
      {
        path: '*',
        element: <Navigate to="login" replace />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Root />
      </PrivateRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/on-tap" />,
      },
      {
        // deprecated route
        path: '/tap',
        element: <Navigate to="/on-tap" replace />,
      },
      { path: '/on-tap', element: <OnTap /> },
      {
        path: '/on-tap/add-keg-to-queue',
        element: <TapKegQueueSelection />,
      },
      {
        path: '/on-tap/change-keg',
        element: <TapChangeKeg />,
        guard: permissions.allowChangeKegsAndLines,
      },
      {
        path: 'on-tap/keg-correction',
        element: <KegCorrection />,
        guard: permissions.allowQualityManagement,
      },
      {
        // * Move it out from /inventory coz /beverages does not contain filter header
        path: '/inventory/beverages',
        element: <SearchBeverage />,
      },
      {
        path: '/inventory',
        element: <Inventory />,
        guard: () => permissions.allowManageInventory() || permissions.allowReadOnlyInventory(),
        children: [
          {
            path: '',
            element: <Navigate to="cooler" />,
          },
          {
            path: 'cooler',
            element: <Cooler />,
          },
          {
            path: 'ordering',
            guard: () =>
              ['BarTrack', 'Owner', 'Manager'].includes(userStore.currentRole?._role_name),
            element: <Ordering />,
          },
          {
            path: 'history',
            guard: () =>
              ['BarTrack', 'Owner', 'Manager'].includes(userStore.currentRole?._role_name),
            element: <History />,
          },
          {
            path: 'add-bulk',
            element: <AddBulkInventory />,
            guard: permissions.allowSystemConfiguration,
          },
          {
            path: '*',
            element: <Navigate to="cooler" />,
          },
        ],
      },
      {
        path: '/digital-menu',
        element: <DigitalMenuLayout />,
        guard: permissions.allowDigitalMenu,
        children: [
          {
            path: '',
            element: <Navigate to="menus" />,
          },
          {
            path: 'menus',
            element: <MenuHome />,
          },

          {
            path: 'lists',
            element: <MenuLists />,
          },
          {
            path: 'designs',
            element: <MenuDesigns />,
          },
          {
            path: '*',
            element: <Navigate to="menus" />,
          },
        ],
      },
      {
        path: '/settings',
        element: <Settings />,
        children: [
          {
            // deprecated route
            path: 'my-beverages',
            element: <Navigate to="/my-beverages" replace />,
          },
          {
            // deprecated route
            path: 'quality-management/*',
            element: <Navigate to="/on-tap" replace />,
          },
          {
            // deprecated route
            path: 'quality-management',
            element: <Navigate to="/quality-management" replace />,
          },
          {
            // deprecated route
            path: 'reports',
            element: <Navigate to="/reports" replace />,
          },
          {
            // deprecated route
            path: 'system-configuration',
            element: <Navigate to="/system-configuration" replace />,
          },
          {
            // deprecated route
            path: 'privacy-policy',
            element: <Navigate to="/end-user-subscription-agreement" replace />,
          },
          {
            // deprecated route
            path: 'contact-us',
            element: <Navigate to="/contact-us" replace />,
          },
          {
            // deprecated route
            path: 'bar-manager',
            element: <BarManagerLayout />,
            guard: permissions.allowBarManager,
            children: [
              {
                // deprecated route
                path: '',
                element: <Navigate to="/bar-manager/users" replace />,
              },
              {
                // deprecated route
                path: 'users',
                element: <Navigate to="/bar-manager/users" replace />,
              },
              {
                path: 'skus',
                guard: permissions.allowManageSKUMapping,
                children: [
                  {
                    // deprecated route
                    path: '',
                    element: <Navigate to="/bar-manager/skus/unmatched" replace />,
                  },
                  {
                    // deprecated route
                    path: ':type',
                    element: <Navigate to="/bar-manager/skus/:type" replace />,
                  },
                  {
                    // deprecated route
                    path: '*',
                    element: <Navigate to="/bar-manager/skus/unmatched" replace />,
                  },
                ],
              },
              {
                // deprecated route
                path: 'cleanings',
                element: <Navigate to="/bar-manager/cleanings" replace />,
                guard: permissions.allowManageCleanings,
              },
              {
                // deprecated route
                path: 'activity-logs',
                element: <Navigate to="/bar-manager/activity-logs" replace />,
              },
              {
                // deprecated route
                path: 'swap/:id',
                element: <Navigate to="/bar-manager/swap/:id" replace />,
              },
              {
                // deprecated route
                path: '*',
                element: <Navigate to="/bar-manager/users" replace />,
              },
            ],
          },
          {
            path: '*',
            element: <Navigate to="/settings" />,
          },
        ],
      },
      {
        path: 'my-beverages',
        element: <MyBeverages />,
        guard: () => permissions.allowManageMyBeverages() || permissions.allowReadOnlyMyBeverages(),
      },
      {
        path: 'my-beverages/add',
        element: <AddBeverage />,
        guard: permissions.allowManageMyBeverages,
      },
      {
        path: 'quality-management',
        element: <QualityManagement />,
        guard: () => permissions.allowQualityManagement() || permissions.allowLineMaintenance(),
        children: [
          {
            path: '',
            element: <Navigate to="system-health" />,
          },
          {
            path: 'system-health',
            element: <SystemHealth />,
          },
          {
            path: 'cleaning-management',
            element: <CleaningManagement />,
          },
          {
            path: 'cleaning-logs',
            element: <CleaningLogs />,
          },
          {
            path: 'draft-system-configuration',
            element: <DraftSystemConfig />,
          },
          {
            path: '*',
            element: <Navigate to="/system-health" />,
          },
        ],
      },
      {
        path: 'quality-management',
        element: <QualityManagement />,
        guard: () => permissions.allowQualityManagement() || permissions.allowLineMaintenance(),
        children: [
          {
            path: '',
            element: <Navigate to="system-health" />,
          },
          {
            path: 'system-health',
            element: <SystemHealth />,
          },
          {
            path: 'cleaning-management',
            element: <CleaningManagement />,
          },
          {
            path: 'cleaning-logs',
            element: <CleaningLogs />,
          },
          //temporary hidden
          // {
          //   path: 'draft-system-configuration',
          //   element: <DraftSystemConfig />,
          // },
          {
            path: '*',
            element: <Navigate to="/system-health" />,
          },
        ],
      },
      {
        path: 'reports',
        guard: permissions.allowManageReports,
        element: <Reports />,
        children: [
          {
            path: '',
            element: <Navigate to="generate" />,
          },
          {
            path: 'generate',
            element: <GeneratedReport />,
          },
          {
            path: 'configuration',
            element: <ReportConfiguration />,
            children: [
              { path: '', element: <MobileConfiguration /> },
              { path: 'delivery', element: <DeliveryRecipients /> },
              { path: 'thresholds', element: <Thresholds /> },
              { path: 'preferences', element: <Preferences /> },
              { path: 'business', element: <BusinessHoursMobileWrapper /> },
              { path: 'admin', guard: permissions.hasAdminAccess(), element: <BarTrackAdmin /> },
              { path: '*', element: <Navigate to="" /> },
            ],
          },
          {
            path: '*',
            element: <Navigate to="generate" />,
          },
        ],
      },
      {
        path: 'employees',
        element: <Employees />,
        guard: () => ['BarTrack', 'Owner', 'Manager'].includes(userStore.currentRole?._role_name),
      },
      {
        path: 'bar-manager',
        element: <BarManagerLayout />,
        guard: permissions.allowBarManager,
        children: [
          {
            path: '',
            element: <Navigate to="skus" />,
          },
          {
            path: 'skus',
            guard: permissions.allowManageSKUMapping,
            element: <BarSKUManager />,
            children: [
              {
                path: 'unmatched',
                element: <UnmatchedMapperTable />,
              },
              {
                path: 'matched',
                element: <MatchedMapperTable />,
              },
              {
                path: 'ignored',
                element: <IgnoredTable />,
              },
              {
                path: '',
                element: <Navigate to="unmatched" />,
              },
            ],
          },
          {
            path: 'activity-logs',
            element: <ActivityLogs />,
          },
          {
            path: 'swap/:id',
            element: <TapSwapKeg />,
          },
          {
            path: '*',
            element: <Navigate to="skus" />,
          },
        ],
      },
      {
        path: 'system-configuration',
        element: <SystemConfiguration />,
        guard: permissions.allowSystemConfiguration,
        children: [
          {
            path: '',
            element: <Navigate to="general" />,
          },
          {
            path: 'general',
            element: <GeneralSettings />,
          },
          {
            path: 'topology-management',
            element: <HardwareManagement />,
          },
          {
            path: 'alerts',
            element: <AlertsSettings />,
            guard: () => userStore.currentRole?._role_name === 'BarTrack',
          },
          {
            path: '*',
            element: <Navigate to="general" />,
          },
        ],
      },
      {
        path: 'end-user-subscription-agreement',
        element: <EndUserServicesAgreement />,
      },
      {
        path: 'contact-us',
        element: <ContactUs />,
      },
      {
        path: 'my-account',
        element: <MyAccount />,
        children: [
          {
            path: '',
            element: <Navigate to="account-info" />,
          },
          {
            path: 'account-info',
            element: <AccountInfo />,
          },
          {
            path: 'notifications',
            element: <Notifications />,
          },
          {
            path: '*',
            element: <Navigate to="account-info" />,
          },
        ],
      },
      {
        path: '/force-logout',
        element: <ForceLogout />,
      },
      {
        path: '/error-404',
        element: <PageNotFound />,
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
];

export default config;
