import React from 'react';
import { observer } from 'mobx-react';

import { Box, Tooltip } from '@mui/material';

import {
  Cellular,
  CellularFailed,
  EthernetOnline,
  EthernetFailed,
  WifiFailed,
  Wifi,
} from 'assets/icons';

import { useMediaQueries } from 'hooks';

import dateUtilities from 'utils/dateUtilities';
import palette from 'theme/palette';

const OnlineStatus = ({
  date = null,
  gateway = null,
  replaced = false,
  muted = false,
  disabled = false,
  iconStyles = {},
}) => {
  const { isMobileOrSmaller } = useMediaQueries();

  // const rawDate = gateway ? gateway.latest_sample_received_at : date;
  // const isoDate = new Date(rawDate).toISOString();

  let isoDate = null;
  let onlineState = false;
  let title = 'No Data';

  if (gateway) {
    const { latest_heartbeat_received_at, latest_sample_received_at } = gateway;
    const heartbeatTimestamp = latest_heartbeat_received_at
      ? new Date(latest_heartbeat_received_at).getTime()
      : null;
    const sampleTimestamp = latest_sample_received_at
      ? new Date(latest_sample_received_at).getTime()
      : null;

    const mostRecentTimestamp = [heartbeatTimestamp, sampleTimestamp]
      .filter(timestamp => timestamp !== null)
      .reduce((min, timestamp) => (min === null || timestamp > min ? timestamp : min), null);

    onlineState = mostRecentTimestamp !== null && Date.now() - mostRecentTimestamp < 900 * 1000;

    const adjustedTimestamp = mostRecentTimestamp !== null ? mostRecentTimestamp : Date.now();

    isoDate = new Date(date || adjustedTimestamp).toISOString();

    title = !mostRecentTimestamp
      ? 'No Data'
      : dateUtilities.formatDateIncludingToday(new Date(isoDate), {
          applyEstablishmentOffset: false,
        });
  } else {
    if (date) {
      isoDate = new Date(date).toISOString();
      onlineState = Math.round((Date.now() - Date.parse(isoDate)) / 1000 / 60) < 15;
      title = !date
        ? 'No Data'
        : dateUtilities.formatDateIncludingToday(new Date(isoDate), {
            applyEstablishmentOffset: false,
          });
    } else {
      // fallback to default values
    }
  }

  const mobileCommonStyle = {
    marginRight: '12px',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
  };
  const desktopCommonStyle = {
    position: 'absolute',
    top: '8px',
    right: '8px',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
  };

  let iconBox = (
    <Box
      sx={{
        backgroundColor: onlineState ? palette.status.green : palette.status.red,
        ...(isMobileOrSmaller ? mobileCommonStyle : desktopCommonStyle),
        ...iconStyles,
      }}
    />
  );

  const replacedIcon = (
    <Box
      sx={{
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: palette.whiteEmphasis.low,
        backgroundColor: 'transparent',
        ...(isMobileOrSmaller ? mobileCommonStyle : desktopCommonStyle),
        ...iconStyles,
      }}
    />
  );

  const mutedDisabledIcon = (
    <Box
      sx={{
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: palette.status.red,
        backgroundColor: 'transparent',
        ...(isMobileOrSmaller ? mobileCommonStyle : desktopCommonStyle),
        ...iconStyles,
      }}
    />
  );

  if (gateway?.metadata?.network_connectivity_mode) {
    const icon_map = {
      wifi: onlineState ? <Wifi /> : <WifiFailed />,
      cellular: onlineState ? <Cellular /> : <CellularFailed />,
      ethernet: onlineState ? <EthernetOnline className="mt" /> : <EthernetFailed className="mt" />,
    };

    iconBox = (
      <Box
        sx={{
          position: 'absolute',
          top: '8px',
          right: '16px',
          width: '10px',
          height: '10px',
          ...iconStyles,
        }}
      >
        {icon_map[gateway.metadata.network_connectivity_mode]}
      </Box>
    );
  }

  if (replaced) {
    iconBox = replacedIcon;
  }

  if (muted || disabled) {
    iconBox = mutedDisabledIcon;
  }

  return isMobileOrSmaller ? iconBox : <Tooltip title={title}>{iconBox}</Tooltip>;
};

export default observer(OnlineStatus);
