import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import concat from 'lodash/concat';
import sumBy from 'lodash/sumBy';
import includes from 'lodash/includes';
import { types, flow } from 'mobx-state-tree';
import { isBefore } from 'date-fns';
import { isAfter } from 'date-fns';

import api from 'services/API';
import { getRootStore } from 'models/root';
import { normalize } from 'utils/diacriticNormalizer';

const sortedByOptions = {
  alphabetical: 'beverageName',
  quantity: 'quantity',
  age: 'ageByOrder',
  'beverage name': 'beverageName',
  'producer name': 'producerName',
  target: 'target',
  'keg size': 'container_size_ml',
  'on tap': 'on_tap',
  'soh - on tap': 'on_tap',
  inventory: 'inventory',
  'soh - inventory': 'inventory',
  'queued kegs': 'totalQueued',
  'total kegs': 'quantity',
  'total cost': 'totalCost',
  total: 'total',
  'soh - total': 'total',
  cost: 'cost',
  'soh - cost': 'cost',
  par: 'par',
  'order qty': 'order_qty',
  'inventory - starting': 'starting',
  'inventory - purchased': 'purchased',
  'inventory - ending': 'ending',
  'inventory - depleted': 'depleted',
  'volume poured': 'poured',
  revenue: 'revenue',
  'avg pour cost': 'pour_cost_pct',
  'avg connected duration': 'avg connected duration',
  'kegs per day': 'per_calendar_day',
  'days left': 'days_left',
};

export const inventoryInitialState = {
  progress: '',
  state: 'done',
  error: '',
  visibilityDrawer: false,
  selectedContainerID: null,
  filters: {
    sortedBy: 'alphabetical',
    beerTypes: [],
    queued: 'included',
    orderBy: 'asc',
    filterBy: 'default',
    kegSizes: [],
    cooler: null,
  },
  searchString: '',
  allowQueued: false,
  forbidOverlapping: false,
  itemIdForSwap: null,
  isTableView: false,
};

export const inventoryModel = types
  .model({
    progress: types.string,
    state: types.enumeration('state', ['done', 'pending', 'error']),
    error: types.string,
    visibilityDrawer: types.boolean,
    selectedContainerID: types.maybeNull(types.number),
    filters: types.maybeNull(
      types.model({
        sortedBy: types.enumeration('sortedBy', [
          'alphabetical',
          'quantity',
          'age',
          'beverage name',
          'producer name',
          'keg size',
          'target',
          'on tap',
          'inventory',
          'soh - inventory',
          'soh - on tap',
          'soh - total',
          'soh - cost',
          'total',
          'cost',
          'par',
          'order qty',
          'queued kegs',
          'total kegs',
          'total cost',
          'inventory - starting',
          'inventory - purchased',
          'inventory - ending',
          'inventory - depleted',
          'volume poured',
          'revenue',
          'avg pour cost',
          'avg connected duration',
          'kegs per day',
          'days left',
        ]),
        beerTypes: types.maybeNull(types.array(types.string)),
        queued: types.enumeration('queued', ['included', 'excluded']),
        orderBy: types.enumeration('orderBy', ['asc', 'desc']),
        filterBy: types.enumeration('filterBy', ['default', 'above target', 'below target']),
        kegSizes: types.maybeNull(types.array(types.integer)),
        cooler: types.maybeNull(types.integer),
      }),
    ),
    searchString: types.string,
    allowQueued: types.boolean,
    forbidOverlapping: types.boolean,
    itemIdForSwap: types.maybeNull(types.integer),
    isTableView: types.boolean,
  })
  .views(self => ({
    get items() {
      const root = getRootStore();
      return root.itemsStore.inventoryItems.filter(
        item => !!root.beveragesStore.getBeverageByID(item.beverage_id),
      );
    },
    get selectedContainer() {
      const { containersStore } = getRootStore();
      return containersStore.kegContainers.find(
        container => container.id === self.selectedContainerID,
      );
    },
    get containers() {
      const root = getRootStore();
      const IDs = Object.keys(groupBy(self.items, 'container_id'));
      return root.containersStore.kegContainersByIDs(IDs);
    },
    get containersIds() {
      const IDs = Object.keys(groupBy(self.items, 'container_id'));
      return IDs.map(e => Number(e));
    },
    get commonContainers() {
      const root = getRootStore();
      const lineItems = root.linesStore.sortedLines.map(e => e.item).filter(e => !!e);
      const IDs = [
        ...new Set([
          ...Object.keys(groupBy(self.items, 'container_id')),
          ...Object.keys(groupBy(lineItems, 'container_id')),
        ]),
      ];

      return root.containersStore.kegContainersByIDs(IDs);
    },
    get commonContainersIds() {
      const root = getRootStore();
      const lineItems = root.linesStore.sortedLines.map(e => e.item).filter(e => !!e);
      return [
        ...new Set([
          ...Object.keys(groupBy(self.items, 'container_id')),
          ...Object.keys(groupBy(lineItems, 'container_id')),
        ]),
      ].map(e => Number(e));
    },
    filteredItems: ({ search = '', queued = false, beerTypes = self.beerTypes(false) }) => {
      let itemsByQueued = queued ? self.items : self.items.filter(item => !item.isQueued);
      let itemsByQueuedAndBeerType = itemsByQueued.filter(item =>
        beerTypes.some(beerType => {
          if (beerType === 'null' && item.beverage._type === null) {
            return true;
          }
          return beerType === item.beverage._type;
        }),
      );
      return search
        ? self.getItemsBySearch(search, itemsByQueuedAndBeerType)
        : itemsByQueuedAndBeerType;
    },
    itemsByBeverageAndContainer: (beverage_id, container_id, shouldIncludeQueued, items) => {
      let itemsForFiltering = self.items;
      if (items && Array.isArray(items)) {
        itemsForFiltering = items;
      }
      itemsForFiltering =
        container_id !== 'All'
          ? itemsForFiltering.filter(item =>
              shouldIncludeQueued
                ? item.beverage_id === beverage_id && item.container_id === container_id
                : item.beverage_id === beverage_id &&
                  item.container_id === container_id &&
                  !item.isQueued,
            )
          : itemsForFiltering.filter(item =>
              shouldIncludeQueued
                ? item.beverage_id === beverage_id
                : item.beverage_id === beverage_id && !item.isQueued,
            );

      // itemsForFiltering = itemsForFiltering.sort((a, b) => {
      //   return new Date(a.addedEvent.earliest_at) - new Date(b.addedEvent.earliest_at);
      // });

      itemsForFiltering = itemsForFiltering.sort((a, b) => {
        return a?.addedEvent?.earliest_at
          ? new Date(a?.addedEvent?.earliest_at) - new Date(b?.addedEvent?.earliest_at)
          : -1;
      });

      return itemsForFiltering;
    },

    filteredBySearch(items, searchString) {
      if (!searchString) {
        return items;
      }
      const searchArray = searchString.toLowerCase().split(' ');
      return items.filter(({ beverage: { _name, _producers_name } }) =>
        searchArray.every(
          element =>
            normalize(_name).toLowerCase().includes(element) ||
            normalize(_producers_name).toLowerCase().includes(element),
        ),
      );
    },

    filteredByQueued(items, queued) {
      if (queued && queued === 'excluded') {
        return items.filter(item => !item.isQueued);
      }
      return items;
    },

    filteredByBeerType(items, beerTypes) {
      if (beerTypes && !beerTypes.length) {
        return items;
      }
      return items.filter(item => {
        return beerTypes.some(beerType => {
          if (!beerType && !item.beverage._type) {
            return true;
          }
          return beerType?.toLowerCase() === item.beverage._type?.toLowerCase();
        });
      });
    },

    filteredByKegSizes(items, kegSizes) {
      if (!kegSizes?.length) {
        return items;
      }
      return items.filter(({ container_id }) => kegSizes.includes(container_id));
    },

    itemsFilteredWithoutQueuedForCurrentLine(items) {
      const { linesStore } = getRootStore();
      if (Array.isArray(items) && items.length > 0) {
        if (
          linesStore &&
          linesStore.currentLine &&
          linesStore.currentLine.queuedItemsIDs &&
          Array.isArray(linesStore.currentLine.queuedItemsIDs) &&
          linesStore.currentLine.queuedItemsIDs.length > 0
        ) {
          return filter(items, item => !includes(linesStore.currentLine.queuedItemsIDs, item.id));
        } else {
          return items;
        }
      } else {
        return [];
      }
    },

    groupItems(items, by) {
      return groupBy(items, by);
    },

    sortGroupItems(groupedItems) {
      const { containersStore } = getRootStore();
      return Object.keys(groupedItems).sort((a, b) => {
        const groupAContainer = containersStore.getContainerByID(a);
        const groupBContainer = containersStore.getContainerByID(b);
        if (groupAContainer.volume_unit_id < groupBContainer.volume_unit_id) {
          return -1;
        } else if (groupAContainer.volume_unit_id > groupBContainer.volume_unit_id) {
          return 1;
        }

        if (groupAContainer.volume_total < groupBContainer.volume_total) {
          return -1;
        } else if (groupAContainer.volume_total > groupBContainer.volume_total) {
          return 1;
        }

        return 0;
      });
    },

    filterOverlapping(items, itemId) {
      const { itemsStore } = getRootStore();
      const currentItem = itemsStore.getItemById(itemId);
      if (!currentItem) {
        return [];
      }
      const withoutItemLines = [];
      const withItemLines = [];
      items.forEach(item => {
        if (item.allItemLines && !item.allItemLines.length) {
          withoutItemLines.push(item);
        } else {
          withItemLines.push(item);
        }
      });
      const filteredItems = withItemLines.filter(item => {
        return item.allItemLines.every(itemLine => {
          if (!itemLine.connected_from) {
            return true;
          }
          if (itemLine.connected_from && currentItem && currentItem.itemLine) {
            const startDate = new Date(currentItem.itemLine.connected_from);
            if (!isAfter(startDate, new Date(itemLine.connected_from))) {
              return false;
            } else {
              if (itemLine.connected_to) {
                if (isBefore(startDate, new Date(itemLine.connected_to))) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }
          }
          return false;
        });
      });

      return concat(withoutItemLines, filteredItems);
    },

    get filteredInventoryItems() {
      const filters = self.filters;
      if (Array.isArray(filters?.beerTypes) && filters?.beerTypes?.length === 0) {
        return [];
      } else {
        let items = self.items;

        const searchString = self.searchString;
        const allowQueued = self.allowQueued;
        const forbidOverlapping = self.forbidOverlapping;

        if (filters?.queued) {
          items = self.filteredByQueued(items, filters.queued);
        }

        if (searchString) {
          items = self.filteredBySearch(items, searchString);
        }

        if (Array.isArray(filters.beerTypes) && filters.beerTypes.length > 0) {
          items = self.filteredByBeerType(items, filters.beerTypes);
        }

        if (Array.isArray(filters.kegSizes) && filters.kegSizes.length > 0) {
          items = self.filteredByKegSizes(items, filters.kegSizes);
        }

        if (filters.cooler) {
          items = items.filter(item => item.cooler_id === filters.cooler);
        }

        if (allowQueued) {
          items = self.itemsFilteredWithoutQueuedForCurrentLine(items);
        }

        if (forbidOverlapping && self.itemIdForSwap) {
          items = self.filterOverlapping(items, self.itemIdForSwap);
        }

        return items;
      }
    },

    get inventoryItems() {
      let items = self.filteredInventoryItems;

      const groupedItems = self.groupItems(items, 'container_id');
      const sortedItems = self.sortGroupItems(groupedItems);
      const mappedItems = sortedItems.map(key => {
        return {
          key: Number(key),
          value: Object.values(groupBy(groupedItems[key], 'beverage_id')).map(itemsByBeverageID => {
            return {
              beverageName: itemsByBeverageID[0].beverage._name,
              producerName: itemsByBeverageID[0].beverage._producers_name,
              quantity: itemsByBeverageID.length,
              totalCost: sumBy(itemsByBeverageID, 'unit_cost'),
              totalQueued: sumBy(itemsByBeverageID, '_queued_count'),
              ageByOrder: (() => {
                const mathFn = self.filters.orderBy === 'asc' ? Math.min : Math.max;
                return mathFn.apply(
                  Math,
                  itemsByBeverageID.map(item => item.age),
                );
              })(),
              isSaved: itemsByBeverageID.some(item => item.savedEvent),
              beverage: itemsByBeverageID[0].beverage,
              container_id: itemsByBeverageID[0].container_id,
              items: self.itemsByBeverageAndContainer(
                itemsByBeverageID[0].beverage.id,
                itemsByBeverageID[0].container_id,
                self.filters.queued === 'included',
                items,
              ),
            };
          }),
        };
      });

      const sortItems = itemsBySize => {
        let items = sortBy(itemsBySize, [sortedByOptions[self.filters.sortedBy]]);
        return self.filters.orderBy === 'asc' ? items : items.reverse();
      };

      return mappedItems.map(orderedItem => ({
        key: orderedItem.key,
        value: sortItems(orderedItem.value),
      }));
    },

    get tableInventoryItems() {
      let items = self.filteredInventoryItems;

      const groupedItemsByBevName = groupBy(items, '_beverages_name');
      return Object.keys(groupedItemsByBevName)
        .map(key => {
          const itemsGroupedByContainer = groupBy(groupedItemsByBevName[key], '_containers_name');
          const allItems = itemsGroupedByContainer
            ? Object.values(itemsGroupedByContainer).flat()
            : [];
          const queued = allItems.filter(({ _queued_count }) => _queued_count !== 0);
          let totalCost = 0;
          allItems
            .filter(({ status_code }) => status_code !== 14)
            .forEach(({ unit_cost }) => (totalCost = totalCost + unit_cost));

          return {
            key: key,
            items: groupedItemsByBevName[key],
            beverage:
              (groupedItemsByBevName[key] &&
                groupedItemsByBevName[key][0] &&
                groupedItemsByBevName[key][0].beverage) ||
              {},
            queuedKegCount: queued.length,
            totalKegCount: allItems.length,
            totalCost: totalCost,
            containers: itemsGroupedByContainer,
          };
        })
        .sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0));
    },

    get groupedInventoryItems() {
      const root = getRootStore();

      const cooler = root.coolersStore.getCoolerById(self.filters.cooler);
      return {
        id: cooler?.id,
        name: cooler?.name,
        items: self.inventoryItems,
      };
    },

    get areThereItemsToRender() {
      const filters = self.filters;
      const searchString = self.searchString;
      let items = self.items;
      if (filters && filters.queued) {
        items = self.filteredByQueued(items, filters.queued);
      }

      if (searchString) {
        items = self.filteredBySearch(items, searchString);
      }
      return searchString !== '' && items.length === 0;
    },

    get beveragesIdsExcludeQueued() {
      return self.items.filter(item => !item.isQueued).map(item => item.beverage_id);
    },

    get beerTypesExcludeQueued() {
      const root = getRootStore();
      return uniqBy(
        root.beveragesStore.all.filter(bev => self.beveragesIdsExcludeQueued.includes(bev.id)),
        '_type',
      )
        .map(({ _type }) => (_type ? _type[0].toUpperCase() + _type.slice(1) : ''))
        .sort();
    },

    get beerTypesIncludeQueued() {
      const root = getRootStore();
      return [
        ...new Set(
          uniqBy(root.beveragesStore.all, '_type').map(({ _type }) =>
            _type ? _type[0].toUpperCase() + _type.slice(1) : '',
          ),
        ),
      ].sort();
    },

    get isDefaultFilters() {
      const root = getRootStore();

      return !!(self.filters &&
      (self.filters.sortedBy === 'alphabetical' || self.filters.sortedBy === 'beverage name') &&
      self.filters.queued === 'included' &&
      self.filters.orderBy === 'asc' &&
      self.filters.filterBy === 'default' &&
      self.filters.beerTypes.length === self.beerTypesIncludeQueued.length &&
      (!self.filters.kegSizes.length ||
        self.filters.kegSizes.length === self.containersIds.length) &&
      self.filters.cooler
        ? self.filters.cooler === root.coolersStore.currentCooler?.id
        : true);
    },

    get isDefaultOrderingFilters() {
      return !!(
        self.filters &&
        (self.filters.sortedBy === 'alphabetical' || self.filters.sortedBy === 'beverage name') &&
        self.filters.orderBy === 'asc' &&
        self.filters.filterBy === 'default' &&
        (!self.filters.kegSizes.length ||
          self.filters.kegSizes.length === self.commonContainers.length)
      );
    },

    get aggregateTapAndInventory() {
      const root = getRootStore();

      const items = [];

      const calcTarget = (par, total) => {
        if (par !== null && par !== 0) {
          return total - par;
        }
        return null;
      };

      const calcOrderQty = target => {
        if (target < 0) {
          return Math.ceil(Math.abs(target));
        }
        return 0;
      };

      const calcDaysLeft = (total, kegPerDay) => {
        if (total && kegPerDay) {
          return total / kegPerDay;
        }

        return null;
      };

      const filteredLineItems = root.linesStore.sortedLines
        .filter(line => {
          if (line.item) {
            return self.filters.beerTypes.some(beerType => {
              if (beerType === '' && line.item.beverage._type === null) {
                return true;
              }
              return beerType.toLowerCase() === line.item.beverage._type.toLowerCase();
            });
          }
          return false;
        })
        .filter(line => {
          return self.searchString
            .toLowerCase()
            .split(' ')
            .every(
              input =>
                normalize(line.beverage?._name).toLowerCase().includes(input) ||
                normalize(line.beverage._producers_name).toLowerCase().includes(input),
            );
        });

      filteredLineItems.forEach(line => {
        if (line.item) {
          const index = items.findIndex(item => item.sku_id === line.item.kegSKU?.id);
          const kegPerDay = root.inventoryKegRateStore.getKegPerDayByBeverageAndContainerId(
            line.item.beverage_id,
            line.item.container?.id,
          );

          if (index !== -1) {
            items[index] = {
              ...items[index],
              on_tap: items[index].on_tap + line.item.percentage / 100,
              total: items[index].total + line.item.percentage / 100,
              target: calcTarget(items[index].par, items[index].on_tap + items[index].inventory),
              cost: items[index].cost + line.item.unit_cost * (line.item.percentage / 100),
              order_qty: calcOrderQty(
                calcTarget(items[index].par, items[index].on_tap + items[index].inventory),
              ),
              days_left: calcDaysLeft(items[index].total + line.item.percentage / 100, kegPerDay),
            };
          } else {
            items.push({
              sku_id: line.item.kegSKU?.id,
              beverage_id: line.item.beverage_id,
              container_id: line.item.container?.id,
              beverageName: line.item._beverages_name,
              producerName: line.beverage._producers_name,
              containerName: line.item.container?.nickname,
              container_size_ml: line.item.container?._volume_total_ml,
              on_tap: line.item.percentage / 100,
              inventory: 0,
              cost: line.item.unit_cost * (line.item.percentage / 100),
              total: line.item.percentage / 100,
              par: line.item.kegSKU?.par,
              target: calcTarget(line.item.kegSKU?.par, line.item.percentage / 100 + 0),
              order_qty: calcOrderQty(
                calcTarget(line.item.kegSKU?.par, line.item.percentage / 100 + 0),
              ),
              per_calendar_day: kegPerDay,
              days_left: calcDaysLeft(line.item.percentage / 100, kegPerDay),
            });
          }
        }
      });

      self.inventoryItems.forEach(container => {
        container.value.forEach(keg => {
          let totalPercentage = 0;
          keg.items.forEach(item => {
            totalPercentage += item.percentage;
          });

          const kegPerDay = root.inventoryKegRateStore.getKegPerDayByBeverageAndContainerId(
            keg.items[0].beverage_id,
            keg.items[0].container.id,
          );

          const index = items.findIndex(item => item.sku_id === keg.items[0].kegSKU?.id);
          if (index !== -1) {
            items[index] = {
              ...items[index],
              inventory: totalPercentage / 100,
              total: items[index].total + totalPercentage / 100,
              target: calcTarget(items[index].par, items[index].on_tap + totalPercentage / 100),
              cost: items[index].cost + keg.items[0].unit_cost * (totalPercentage / 100),
              order_qty: calcOrderQty(
                calcTarget(items[index].par, items[index].on_tap + totalPercentage / 100),
              ),
              days_left: calcDaysLeft(items[index].total + totalPercentage / 100, kegPerDay),
            };
          } else {
            items.push({
              sku_id: keg.items[0].kegSKU?.id,
              beverage_id: keg.items[0].beverage_id,
              container_id: keg.items[0].container.id,
              beverageName: keg.beverageName,
              producerName: keg.beverage._producers_name,
              containerName: keg.items[0].container.nickname,
              container_size_ml: keg.items[0].container._volume_total_ml,
              on_tap: 0,
              inventory: totalPercentage / 100,
              cost: keg.items[0].unit_cost * (totalPercentage / 100),
              total: totalPercentage / 100,
              par: keg.items[0].kegSKU?.par,
              target: calcTarget(keg.items[0].kegSKU?.par, totalPercentage / 100 + 0),
              order_qty: calcOrderQty(
                calcTarget(keg.items[0].kegSKU?.par, totalPercentage / 100 + 0),
              ),
              per_calendar_day: kegPerDay,
              days_left: calcDaysLeft(totalPercentage / 100, kegPerDay),
            });
          }
        });
      });

      const filterAndSortItems = items => {
        let tempItems = items;

        if (self.filters.kegSizes.length) {
          tempItems = tempItems.filter(item => self.filters.kegSizes.includes(item.container_id));
        }

        if (self.filters.filterBy !== 'default') {
          tempItems = tempItems.filter(item => {
            if (self.filters.filterBy === 'above target') {
              return item.target > 0;
            } else {
              return item.target < 0;
            }
          });
        }

        if (['per_calendar_day', 'days_left'].includes(sortedByOptions[self.filters.sortedBy])) {
          const validItems = tempItems.filter(
            item =>
              item[sortedByOptions[self.filters.sortedBy]] !== 0 &&
              item[sortedByOptions[self.filters.sortedBy]] !== null,
          );

          const invalidItems = tempItems.filter(
            item =>
              item[sortedByOptions[self.filters.sortedBy]] === 0 ||
              item[sortedByOptions[self.filters.sortedBy]] === null,
          );

          tempItems = sortBy(validItems, [sortedByOptions[self.filters.sortedBy]]);
          const orderResult = self.filters.orderBy === 'asc' ? tempItems : tempItems.reverse();
          return [...orderResult, ...invalidItems];
        } else {
          tempItems = sortBy(tempItems, [sortedByOptions[self.filters.sortedBy]]);
          return self.filters.orderBy === 'asc' ? tempItems : tempItems.reverse();
        }
      };

      return filterAndSortItems(items);
    },

    containersByBeverage: (beverage_id, container_id, shouldIncludeQueued) => {
      const { containersStore } = getRootStore();
      const IDs = Object.keys(
        groupBy(
          self.itemsByBeverageAndContainer(beverage_id, container_id, shouldIncludeQueued),
          'container_id',
        ),
      ).map(id => Number(id));
      return containersStore.kegContainersByIDs(IDs);
    },
  }))
  .actions(self => ({
    setSelectedContainerID: id => {
      self.selectedContainerID = Number(id);
    },

    addItems: flow(function* (body) {
      try {
        const root = getRootStore();
        const response = yield api.addItems(body);
        const { added } = response?.data?.result || {};

        if (added) {
          if (Array.isArray(added.beverages)) {
            added.beverages.forEach(beverage => root.beveragesStore.updateOrInsert(beverage));
          }

          if (Array.isArray(added.skus)) {
            added.skus.forEach(sku => root.skuStore.handleInsertSku(sku));
          }

          if (added.items) {
            const items = added.items.map(({ _item, _item_events_summary }) => {
              const itemEventsSummaryArray = Array.isArray(
                _item_events_summary?._item_events_summary,
              )
                ? _item_events_summary._item_events_summary
                : [];

              return {
                ..._item,
                _item_events_summary: itemEventsSummaryArray,
              };
            });

            const updatedItems = [...root.itemsStore.items, ...items];
            root.itemsStore.updateItems(updatedItems);
          }
        }

        return response?.data?.result;
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    getItemsBySearch: (search, items) => {
      const searchInputs = search.toLowerCase().split(' ');
      return items.filter(({ beverage: { _name, _producers_name } }) =>
        searchInputs.every(
          input =>
            normalize(_name).toLowerCase().includes(input) ||
            normalize(_producers_name).toLowerCase().includes(input),
        ),
      );
    },

    setDefaultFilterBeerTypes() {
      self.filters = { ...self.filters, beerTypes: self.beerTypesIncludeQueued };
    },

    resetFilters(path) {
      const root = getRootStore();

      self.filters = {
        sortedBy: 'beverage name',
        beerTypes: self.beerTypesIncludeQueued,
        queued: 'included',
        orderBy: 'asc',
        filterBy: 'default',
        kegSizes: path === 'ordering' ? self.commonContainersIds : self.containersIds,
        cooler: root.coolersStore.currentCooler?.id,
      };
    },

    setFilters(filters) {
      const root = getRootStore();

      if (filters === null) {
        self.filters = {
          sortedBy: 'beverage name',
          beerTypes: self.beerTypesIncludeQueued,
          queued: 'included',
          orderBy: 'asc',
          filterBy: 'default',
          kegSizes: self.containersIds,
          cooler: root.coolersStore.currentCooler?.id,
        };
      } else {
        self.filters = {
          beerTypes: filters.beerTypes || self.filters.beerTypes,
          orderBy: filters.orderBy || self.filters.orderBy,
          queued: filters.queued || self.filters.queued,
          sortedBy: filters.sortedBy || self.filters.sortedBy,
          filterBy: filters.filterBy || self.filters.filterBy,
          kegSizes: filters.kegSizes || self.filters.kegSizes,
          cooler: filters.cooler || self.filters.cooler,
        };
      }
    },

    setSearch(value) {
      self.searchString = value || '';
    },

    setAllowQueued(value) {
      self.allowQueued = value;
    },

    setFilterOverlapping(value) {
      self.forbidOverlapping = value;
    },

    setItemIdForSwap(value) {
      self.itemIdForSwap = value;
    },

    setView(value) {
      self.isTableView = value;
    },
  }));
