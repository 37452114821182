import { getRootStore } from 'models/root';

const numberUtilities = {
  roundThresholds: num => {
    if (!num) return '0.0';
    const result = `${Number(num).toFixed(1)}.0`;

    return result.slice(0, result.indexOf('.') + 2);
  },

  celsiusToFahrenheit: (val, round = false, signsAmount = 0) => {
    if (!Number.isFinite(val)) return null;

    let result = val * (9 / 5) + 32;

    if (round && Number.isInteger(Math.abs(signsAmount))) {
      const multer = Math.pow(10, Math.abs(signsAmount));

      return Math.round((result + Number.EPSILON) * multer) / multer;
    }

    return result;
  },

  fahrenheitToCelsius: (val, signsAmount = 0) => {
    if (!Number.isFinite(val)) return null;

    let result = (val - 32) * (5 / 9);

    if (signsAmount && Number.isInteger(Math.abs(signsAmount))) {
      const multer = Math.pow(10, Math.abs(signsAmount));

      return Math.round((result + Number.EPSILON) * multer) / multer;
    }

    return result;
  },

  ftToMeters: val => val * 0.3048,
  metersToFt: val => val / 0.3048,

  convertVolumeName: () => {
    const root = getRootStore();
    const unit_system = root.establishmentStore.unitPreference;
    return unit_system === 'English' ? 'gaL' : 'L';
  },

  convertVolumeValue: value => {
    const root = getRootStore();
    const unit_system = root.establishmentStore.unitPreference;
    return (unit_system === 'English' ? value / 3.78541178 : value).toFixed(2);
  },

  convertTemperature: value => {
    if (!value) return null;

    const root = getRootStore();
    const unit_system = root.establishmentStore.unitPreference;

    if (unit_system === 'English') {
      return (value * (9 / 5) + 32).toFixed(1);
    }
    return value.toFixed(1);
  },

  galToMl: val => val * 3785.411784,
  mlToGal: val => val / 3785.411784,
  roundToDecimal: (val, signsAmount = 2) => {
    if (!Number.isFinite(val)) return null;

    const multer = Math.pow(10, Math.abs(signsAmount));

    return Math.round((val + Number.EPSILON) * multer) / multer;
  },

  calcInitialTotal: (total_volume_ml, percentage) =>
    numberUtilities.roundToDecimal(numberUtilities.mlToGal((total_volume_ml * percentage) / 100)),

  calcOnlineStatus: timestamp => (timestamp ? Math.round(timestamp / 60) < 5 : !!timestamp),
};

export default numberUtilities;
