import { types } from 'mobx-state-tree';
import { Identifier } from './line-identifier';

const Heartbeat = types.model({
  id: types.identifierNumber,
  sensor_temp_c: types.number,
  received_at: types.string,
});

const Pour = types.model({
  id: types.identifierNumber,
  poured_at: types.string,
  conditions: types.maybeNull(types.array(types.number)),
  conditions_set_at: types.maybeNull(types.string),
  sensor_temp_c_stop: types.maybeNull(types.number),
});

const Geometries = types.model({
  segments: types.array(
    types.model({
      inner_diameter_in: types.maybeNull(types.number),
      length_m: types.maybeNull(types.number),
    }),
  ),
  displacement_m: types.maybeNull(types.number),
});

const Metadata = types.model({
  choker: types.maybeNull(types.boolean),
  fob_installed: types.maybeNull(types.boolean),
  glycol: types.maybeNull(types.boolean),
  splitter: types.maybeNull(
    types.model({
      split_count: types.maybeNull(types.number),
      value: types.maybeNull(types.boolean),
    }),
  ),
});

export const Line = types.model({
  id: types.identifierNumber,
  establishment_id: types.maybeNull(types.integer),
  cooler_id: types.maybeNull(types.number),
  identifiers: types.maybeNull(Identifier),
  status_code: types.maybeNull(types.integer),
  archived: types.maybeNull(types.boolean),
  scheduled_cleaning_at: types.maybeNull(types.string),
  sort_value: types.maybeNull(types.number),
  _cleanings_cleaned_to_current: types.maybeNull(types.string),
  _cleanings_cleaned_from_current: types.maybeNull(types.string),
  _cleanings_cleaned_to_latest: types.maybeNull(types.string),
  _cleanings_cleaned_from_latest: types.maybeNull(types.string),
  _heartbeats_latest_heartbeat: types.maybeNull(Heartbeat),
  _pours_latest_pour: types.maybeNull(Pour),
  _item_lines_latest_item_id: types.maybeNull(types.number),
  _bar_ids: types.maybeNull(types.array(types.number)),
  line_identifier: types.maybeNull(types.string),
  geometries: types.maybeNull(Geometries),
  metadata: types.maybeNull(Metadata),
  tap_identifier: types.maybeNull(types.string),
});
