export const HEADER_HEIGHT = 60;
export const BIG_HEADER_HEIGHT = 108;
export const BOTTOM_NAVIGATION_HEIGHT = 60;
export const UPDATE_TOOLBAR_HEIGHT = 30;
export const NO_INTERNET_HEIGHT = 30;
export const NO_INTERNET_HEIGHT_MOBILE = 50;
export const SYSTEM_OFFLINE_BANNER_HEIGHT = 40;

export const MENU_OPENED_WIDTH = 260;
export const MENU_COLLAPSED_WIDTH = 54;
