import React from 'react';
import { observer } from 'mobx-react';

import { Box, styled } from '@mui/material';
import {} from '@mui/material/styles';

const CalibrationTab = () => {
  return (
    <StyledRootBox>
      <Box className="tab_content">Calibration</Box>
    </StyledRootBox>
  );
};

export default observer(CalibrationTab);

const StyledRootBox = styled(Box)(({ theme: { palette } }) => ({
  padding: '16px',
  backgroundColor: palette.background.main,
}));
