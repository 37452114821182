import { types, flow } from 'mobx-state-tree';

import groupBy from 'lodash/groupBy';
import { differenceInSeconds, isSameDay, format } from 'date-fns';

import api from 'services/API';
import { CleaningsStatistic } from 'models/types';
import { dateUtilities, stringUtilities } from 'utils';

export const cleaningsStatisticsInitialState = {
  isLoaded: false,
  all: [],
  filters: {
    fromDate: null,
    toDate: null,
  },
  page: 1,
  limit: 20,
  state: 'done',
  updated: null,
};

const CleaningsStatisticsWithView = CleaningsStatistic.views(self => ({
  get lineDuration() {
    if (self.cleaned_from && self.cleaned_to) {
      const from = dateUtilities.convertUTCToZonedTime(self.cleaned_from);
      const to = dateUtilities.convertUTCToZonedTime(self.cleaned_to);

      const diff = differenceInSeconds(to, from);
      return dateUtilities.secondsToDuration(diff);
    } else {
      return 'N/A';
    }
  },

  get lineStartTime() {
    return dateUtilities.formatDate(dateUtilities.convertUTCToZonedTime(self.cleaned_from));
  },

  get lineFinishTime() {
    if (!self.cleaned_to) return '-';
    const same = isSameDay(new Date(self.cleaned_from), new Date(self.cleaned_to));
    return same
      ? dateUtilities.formatDate(dateUtilities.convertUTCToZonedTime(self.cleaned_to))
      : `${dateUtilities.formatDate(
          dateUtilities.convertUTCToZonedTime(self.cleaned_to),
        )} (${format(new Date(dateUtilities.convertUTCToZonedTime(self.cleaned_to)), 'M/d')})`;
  },
}));

export const cleaningsStatisticsModel = types
  .model({
    isLoaded: types.boolean,
    all: types.array(CleaningsStatisticsWithView),
    filters: types.maybeNull(
      types.model({
        fromDate: types.maybeNull(types.Date),
        toDate: types.maybeNull(types.Date),
      }),
    ),
    page: types.integer,
    limit: types.integer,
    state: types.enumeration('state', ['done', 'pending', 'error']),
    updated: types.maybeNull(types.Date),
  })
  .views(self => ({
    get getUpdated() {
      return self.updated;
    },
    get sorted() {
      return self.all
        .slice()
        .sort((a, b) => Date.parse(a.cleaned_from) - Date.parse(b.cleaned_from));
    },

    get groupedLines() {
      const groupedLines = {
        byStartedDate: {},
        byLineId: {},
      };

      self.sorted.forEach(cleaning => {
        const formattedDate = dateUtilities.makeDateFormatMDYYYY(
          dateUtilities.convertUtcToZonedTime(cleaning.cleaned_from),
        );

        if (!groupedLines.byStartedDate[formattedDate]) {
          groupedLines.byStartedDate[formattedDate] = {
            originalTimestamp: null,
            items: [],
          };
        }

        if (!groupedLines.byLineId[cleaning.line_id]) {
          groupedLines.byLineId[cleaning.line_id] = {
            originalTimestamp: null,
            items: [],
          };
        }

        groupedLines.byStartedDate[formattedDate].originalTimestamp =
          dateUtilities.convertUtcToZonedTime(cleaning.cleaned_from);
        groupedLines.byStartedDate[formattedDate].items.push(cleaning);

        groupedLines.byLineId[cleaning.line_id].originalTimestamp =
          dateUtilities.convertUtcToZonedTime(cleaning.cleaned_from);
        groupedLines.byLineId[cleaning.line_id].items.push(cleaning);
      });

      return groupedLines;
    },

    get groupedAndMappedLines() {
      return Object.keys(self.groupedLines.byStartedDate)
        .map(key => {
          return {
            key: key,
            originalTimestamp: self.groupedLines.byStartedDate[key].originalTimestamp,
            items: self.groupedLines.byStartedDate[key].items,
          };
        })
        .reverse();
    },

    get hasMore() {
      return self.groupedAndMappedLines.length > self.page * self.limit;
    },

    getStatisticByLineId(id) {
      return self.all.find(line => line.line_id === id);
    },
    getTemperatureById(id) {
      const cooler = self.all.find(line => line.id === id);

      if (
        cooler?._pours_statistics?.sensor_temp_c?.max === 'N/A' ||
        !cooler?._pours_statistics?.sensor_temp_c?.max
      ) {
        return 'N/A';
      }

      return stringUtilities
        .makeTemperature(cooler?._pours_statistics?.sensor_temp_c?.max)
        .replace(/(°F|°C)/, '');
    },
    getGroupedStatistic() {
      return groupBy(self.all.slice(), 'line_id');
    },
  }))
  .actions(self => ({
    fetch: flow(function* () {
      try {
        self.state = 'pending';

        const params = {
          'from[cleaned_from]': self.filters?.fromDate.toISOString(),
          'to[cleaned_from]': self.filters?.toDate.toISOString(),
        };
        const response = yield api.getCleaningsStatistics(params);
        if (response && response.data && response.data.result) {
          self.all.replace(response.data.result);
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
        } else {
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
        }
      } catch (err) {
        self.isLoaded = true;
        self.state = 'error';
        self.updated = new Date();
        console.error(err);
        return Promise.reject(err);
      }
    }),
    addToStatistics(data) {
      self.all.push(...data);
    },

    setPage(value) {
      self.page = value;
    },

    setFilters(filters) {
      self.filters = filters;
      self.fetch();
    },
  }));
