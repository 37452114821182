import { types, flow } from 'mobx-state-tree';

import api from 'services/API';
import { Notification, Condition } from 'models/types';
import { getRootStore } from 'models/root';

import { numberUtilities, stringUtilities } from 'utils';

export const notificationInitialState = {
  isLoaded: false,
  all: [],
  conditions: [],
  selectedAlertId: null,
};

export const notificationModel = types
  .model({
    isLoaded: types.boolean,
    all: types.array(Notification),
    conditions: types.array(Condition),
    selectedAlertId: types.maybeNull(types.number),
  })
  .views(self => ({
    get extended() {
      const root = getRootStore();

      return self.all.map(alert => {
        const condition = self.conditions.find(c => c.id === alert.condition_id);

        const alarms = root.deviceHistoryStore.alarms.filter(
          ({ sensor_id }) => sensor_id === condition?.context?.id,
        );

        const min = numberUtilities.celsiusToFahrenheit(
          stringUtilities.ruleToValue(alarms.find(a => a?.rule.includes('<'))?.rule),
          true,
        );
        const max = numberUtilities.celsiusToFahrenheit(
          stringUtilities.ruleToValue(alarms.find(a => a?.rule.includes('>'))?.rule),
          true,
        );

        return {
          ...alert,
          condition,
          alarms,
          min,
          max,
        };
      });
    },
    get unread() {
      return self.extended
        .filter(n => n.status_code === 0)
        .sort(
          (a, b) =>
            Date.parse(b._conditions_earliest_trigger_at) -
            Date.parse(a._conditions_earliest_trigger_at),
        );
    },
    get selectedAlert() {
      return self.extended.find(alert => alert.id === self.selectedAlertId);
    },
  }))
  .actions(self => ({
    markAsRead: flow(function* (id) {
      try {
        const response = yield api.patchNotification({ status_code: 1 }, id);
        const updatedNotification = response.data?.row;

        self.all.replace([
          ...self.all.filter(e => e.id !== updatedNotification.id),
          updatedNotification,
        ]);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    setNotifications(notifications) {
      self.all.replace(notifications);
    },

    setConditions(conditions) {
      self.conditions.replace(
        conditions.map(condition => ({
          ...condition,
          criteria_value: numberUtilities.celsiusToFahrenheit(
            stringUtilities.ruleToValue(condition.criteria),
            true,
          ),
        })),
      );
    },

    setAlertId(id = null) {
      self.selectedAlertId = Number(id);
    },
  }));
