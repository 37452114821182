import { Box } from '@mui/material';
import { ReferenceContext } from 'context/reference';
import { useContext, useEffect, useRef } from 'react';

const SystemOfflinePlaceholder = () => {
  const ref = useRef();
  const { systemOfflineBanner } = useContext(ReferenceContext);
  const { setRef } = systemOfflineBanner;

  useEffect(() => {
    setRef(ref);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box ref={ref} />;
};

export default SystemOfflinePlaceholder;
