import { ChevronRight, LocationOnOutlined } from '@mui/icons-material';
import { Card, Typography, styled } from '@mui/material';
import { GatewayIcon24x24, SensorGreyIcon24x24 } from 'assets/icons';
import DiagnosticDetails from 'components/shared/SystemOffline/DetailsDrawer/DetailsDrawerCard/DiagnosticDetails';
import { useStore } from 'hooks';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';

function formatDateToTodayTime(timestamp) {
  const date = new Date(timestamp);

  const today = new Date();
  const isToday = date.toDateString() === today.toDateString();

  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
  });

  const formattedTime = timeFormatter.format(date);

  if (isToday) {
    return `Today at ${formattedTime}`;
  } else {
    return `${date.toLocaleDateString('en-US')} at ${formattedTime}`;
  }
}

const DetailsDrawerCard = ({ device }) => {
  const { topologyManagementStore } = useStore();

  const isGateway = device.deviceType === 'gateway';
  const isSensor = !!device.line;
  const [openDiagnostic, setOpenDiagnostic] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [selectedDevices, setSelectedDevices] = useState({
    gateway: null,
    bru: null,
    sensor: null,
  });

  const currentDevice = useMemo(() => {
    return isGateway
      ? { gateway: device }
      : { gateway: device.gateway, bru: device.bru, sensor: device };
  }, [device, isGateway]);

  const handleDeviceClick = (type, device) => {
    setSelectedDevices(prev => ({ ...prev, ...device }));
    setSelectedType(type);
    topologyManagementStore.setSelectedGatewayId(device.gateway?.id);

    if (type === 'sensor') {
      topologyManagementStore.fetchSensorDiagnosticsInfo({
        gateway_identifier: device.gateway?.identifier,
        gateway_bru_address: device.bru?.gateway_bru_address,
        bru_sensor_address: device.sensor?.bru_sensor_address,
      });
    }

    setOpenDiagnostic(true);
  };

  const handleCloseDiagnostic = () => {
    setOpenDiagnostic(false);
  };

  const renderIcon = () => {
    if (isGateway) {
      return (
        <div className={'gatewayIcon'}>
          <GatewayIcon24x24 />
        </div>
      );
    } else if (isSensor) {
      return (
        <div className={'sensorIcon'}>
          <SensorGreyIcon24x24 />
        </div>
      );
    }
  };

  const renderContent = () => {
    if (isGateway) {
      const { name, metadata } = device;
      const location = metadata?.installation_location || '';
      return (
        <>
          <Typography variant={'h6'} className={'identifier'}>
            {name}
          </Typography>
          {location && (
            <span className={'location'}>
              <LocationOnOutlined htmlColor={'rgba(255,255,255,0.6)'} fontSize={'small'} />
              <Typography className={'info'} variant={'caption'}>
                {location}
              </Typography>
            </span>
          )}
        </>
      );
    } else if (isSensor) {
      const beverageName = device?.line?.item?._beverages_name || 'Empty Tap';
      const { identifier } = device.line;
      const { gateway_bru_address, gateway_name, latest_heartbeat_received_at } = device;
      return (
        <>
          <Typography variant={'h6'} className={'identifier'}>
            Line {identifier} ({beverageName})
          </Typography>
          <Typography className={'info lastUpdate'} variant={'caption'}>
            {formatDateToTodayTime(latest_heartbeat_received_at)}
          </Typography>
          <Typography className={'info'} variant={'caption'}>
            {gateway_name} / BRU #{gateway_bru_address}
          </Typography>
        </>
      );
    }
  };

  if (isGateway || (isSensor && device.line)) {
    return (
      <StyledDiv className={isGateway ? 'gateway' : 'sensor'}>
        <Card
          className={'deviceCardContainer'}
          onClick={() => handleDeviceClick(isGateway ? 'gateway' : 'sensor', currentDevice)}
        >
          <div className={'deviceCard'}>
            <div className={'icon'}>
              {renderIcon()}
              <div className={'indicator'} />
            </div>
            <div className={'content'}>{renderContent()}</div>
            <div className={'chevron'}>
              <ChevronRight />
            </div>
          </div>
        </Card>
        {openDiagnostic && (
          <DiagnosticDetails
            isOpen={openDiagnostic}
            handleClose={handleCloseDiagnostic}
            type={selectedType}
            cooler={device.cooler}
            devices={selectedDevices}
          />
        )}
      </StyledDiv>
    );
  }
};

export default observer(DetailsDrawerCard);

const StyledDiv = styled('div')(({ theme: { palette } }) => ({
  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
  border: `1px solid ${palette.outline.main}`,
  borderRadius: '8px',
  cursor: 'pointer',
  backgroundColor: palette.background.overlay2,
  '.deviceCardContainer': {
    '&.MuiPaper-root': {
      borderRadius: '8px',
    },
  },
  '.deviceCard': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  '.identifier': {
    marginBottom: '4px',
    color: palette.whiteEmphasis.high,
  },
  '.location': {
    display: 'flex',
    alignItems: 'center',
  },
  '.chevron': {
    marginLeft: 'auto',
  },
  '.info': {
    color: palette.whiteEmphasis.medium,
  },
  '.icon': {
    backgroundColor: palette.background.main,
    width: '48px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    marginRight: '12px',
    position: 'relative',
  },
  '.indicator': {
    backgroundColor: palette.status.red,
    height: '8px',
    width: '8px',
    borderRadius: '50%',
    position: 'absolute',
    bottom: '4px',
    right: '4px',
  },
  '.content': {
    display: 'flex',
    flexDirection: 'column',
  },
  '.lastUpdate': {
    marginBottom: '4px',
  },
}));
