import { types } from 'mobx-state-tree';

export const SensorMetadata = types.model({
  latest_pour: types.maybeNull(types.string),
  latest_calibration: types.maybeNull(types.string),
  pga_gain: types.maybeNull(types.number),
  sensor_vfr: types.maybeNull(types.number),
  volume_scale_factor: types.maybeNull(types.number),
  calibrated_tof: types.maybeNull(types.string),
  signal_strength: types.maybeNull(types.number),
  sensor_temp_c: types.maybeNull(types.number),
});
